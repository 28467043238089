import React, { useContext } from 'react'

import { DataContext } from './DataContext'
import NoDataComponent from '../../../elem/table/client/NoDataComponent'
import TableContent from '../../../elem/table/client/TableContent'
import parseColumns from '../../../../utils/table/parseColumns'
import withConfig from '../../../wrappers/withConfig'

export default withConfig(({ config }) => {
    const { detailData, filterData, visibleTab, isLoading } = useContext(DataContext)
    const { ID_COLUMN, EXPLORER_LINK_COLUMN } = config
    if (
            detailData &&
            detailData.pressure &&
            filterData &&
            filterData.pressure &&
            visibleTab === 'pressure'
    ) {
        const formFields = filterData.pressure
        const pressureDetailsData = detailData.pressure
        const columns = parseColumns(pressureDetailsData, formFields, EXPLORER_LINK_COLUMN, ID_COLUMN)
        return (
            <div className="is-size-7">
                <NoDataComponent data={pressureDetailsData} isLoading={isLoading}/>
                <TableContent
                    name="pressureDetails"
                    columns={columns}
                    data={pressureDetailsData}
                    containerClass={'detailTableWrapper'}
                    loading={isLoading}
                    initialSort={'MonitorReqStartDate'}
                    initialSortDesc={true}
                />
            </div>
        )

    } else {
        return null
    }
})