import React, { useContext } from 'react'
import { FaAngleUp, FaAngleDown } from 'react-icons/fa'

import PanelHeader from '../../../../elem/panel/PanelHeader'
import { DataContext } from './DataContext'
// import TimeWindowSelectorButton from '../../../../elem/chart/TimeWindowSelectorButton'
import ResetDropdownButton from '../../../../elem/chart/ResetDropDownButton'
import SelectorButton from '../../../../elem/chart/SelectorButton'

export default () => {
    const {
        // timeWindow,
        // displayTimeWindowDropdown,
        // toggleTimeWindowDropdown,
        displayMeasureWindowDropdown,
        toggleMeasureWindowDropdown,
        measureList,
        toggleResetExpanded,
        resetExpanded,
        setCollapsed,
        isCollapsed,
        displayGroupingWindowDropdown,
        toggleGroupingWindowDropdown,
    } = useContext(DataContext)

    return (
        <PanelHeader extraClass={'aggregatePanelHeader'}>
            <div className="panelHeaderText">Aggregate Pressure-Volume Chart</div>
            <div className="panelButtons">
                <ResetDropdownButton resetExpanded={resetExpanded} toggleResetExpanded={toggleResetExpanded} />
                {measureList && measureList.length ? (<>
                    <SelectorButton
                        displayWindowDropdown={ displayGroupingWindowDropdown}
                        toggleWindowDropdown={toggleGroupingWindowDropdown}
                        label={`Report Grouping`}
                    />
                    <SelectorButton
                        displayWindowDropdown={displayMeasureWindowDropdown}
                        toggleWindowDropdown={toggleMeasureWindowDropdown}
                        label={`Measures`}
                    />
                </>) : null}
                {/* <TimeWindowSelectorButton
                    timeWindow={timeWindow}
                    displayTimeWindowDropdown={displayTimeWindowDropdown}
                    toggleTimeWindowDropdown={toggleTimeWindowDropdown}
                /> */}
                <div className="panelHeaderIconWrapper">
                    <div
                        className="panelHeaderIconDiv"
                        data-tip={`${
                            isCollapsed
                                ? `Expand Aggregate`
                                : `Hide Aggregate`
                        }`}
                        data-for={`aggregate-panel-toggle`}
                        onClick={() =>
                            setCollapsed(!isCollapsed)
                        }
                    >
                        {isCollapsed ? (
                            <FaAngleUp />
                        ) : (
                            <FaAngleDown />
                        )}        
                    </div>
                </div> 
            </div>
        </PanelHeader>
    )
}
