import React from 'react'

import { UpArrow, DownArrow } from '../Arrows'

export default ({ 
    displayWindowDropdown,
    toggleWindowDropdown,
    label,
    inHeader=true
}) => {
    return (
        <div
            className="panelButton"
            onClick={() => toggleWindowDropdown(!displayWindowDropdown)}
        >
            <span className="panelTimeOptionText">{label}</span>
            <span className="panelTimeOptionDropdown">
                {displayWindowDropdown ? (
                    <UpArrow color={'white'} className={`${inHeader ? 'is-white' : ''}`} />
                ) : (
                    <DownArrow color={'white'} className={`${inHeader ? 'is-white' : ''}`} />
                )}
            </span>
        </div>
    )
}
