import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'

import withConfig from '../../../wrappers/withConfig'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import actions from '../../../../utils/submissions/actions'
import { UserContext } from '../../../wrappers/UserContext'
import toast from '../../../elem/Toast'

const TableContext = createContext(null)

const TableContextProvider = ({ config, children }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [loading, setLoading] = useState(true)
    const [uploads, setUploads] = useState(null)
    const { API_URL } = config
    const [selectedUploads, setSelectedUploads] = useState([])
    const [bulkComments, setBulkComments] = useState('')
    const [displayBulkApproveModal, setDisplayBulkApproveModal] = useState(false)
    const [displayBulkDenyModal, setDisplayBulkDenyModal] = useState(false)
    const { user } = useContext(UserContext)
    const [confirmDeleteModalData, setConfirmDeleteModalData] = useState({ display: false, uploadId: null, formType: null })

    // fetch uploads
    useEffect(() => {
        actions.getTableData(authenticatedFetch, API_URL, setLoading, setUploads)
    }, [])

    const resolveBulkModalState = (buttonAction, state) => {
        setBulkComments('') //clear the comments field every time we display or hide the delete modal
        if (buttonAction === 'Approve')
            setDisplayBulkApproveModal(state)
        else 
            setDisplayBulkDenyModal(state)
    }

    // bulk submit
    const bulkSubmit = useCallback(action => {
        if (action === 'Deny' && !bulkComments) {
            toast({
                level: 'error',
                message:
                    `Comments are required for denied submissions.`,
                alert: true,
            })
            return
        }
    
        const selectedUploadIds = selectedUploads.map(x => x['Form ID'])
        actions.bulkSubmit(action, authenticatedFetch, API_URL, selectedUploadIds, bulkComments, user.profile.name, () => {
            let successMessage 
            switch (action) {
                case 'Approve':
                    successMessage = 'All selected forms have been approved'
                    break
                case 'Deny':
                    successMessage = 'All selected forms have been denied'
                    break
                default:
                    successMessage = 'All selected forms have been submitted and can now be viewed in the Submitted Forms tab'
                    break;
            }
            // alert the user that upload was successful
            toast({
                level: 'info',
                message: successMessage,
                alert: true
            })
            // refetch table data
            actions.getTableData(authenticatedFetch, API_URL, setLoading, setUploads)
            resolveBulkModalState(action, false)
        })
    }, [selectedUploads, user, bulkComments])
    
    return (
        <TableContext.Provider value={{ 
            uploads,
            setUploads,
            selectedUploads,
            setSelectedUploads,
            loading,
            bulkSubmit,
            bulkComments,
            setBulkComments,
            confirmDeleteModalData,
            setConfirmDeleteModalData,
            displayBulkApproveModal,
            setDisplayBulkApproveModal,
            setDisplayBulkDenyModal,
            displayBulkDenyModal,
            resolveBulkModalState,
        }}>
            {children}
        </TableContext.Provider>
    )
}

export { TableContext }
export default withConfig(TableContextProvider)
