import React, { useContext } from 'react'
import { Controller } from 'react-hook-form'
import ReactSelect from 'react-select'
import { FaInfoCircle } from 'react-icons/fa'

import Tooltip from '../Tooltip'
import SelectStyles from './SelectStyles'
import { ParameterContext } from '../../wrappers/ParameterContext'
import { PanelStyleContext } from '../panel/PanelStyleContext'

const mapValuesToOptions = (values, fieldAccessorMap) => {
    const options = values
        .filter(v => v[fieldAccessorMap.value] !== '')
        .map(v => {
            return {
                label: v[fieldAccessorMap.label],
                value: v[fieldAccessorMap.value],
            }
        })
    return options
}

const getInitialValue = (name, formName, params, options) => {
    const filterName = `${name}Value`
    const initialFilterValue = params[formName] && params[formName][filterName] ? params[formName][filterName] : ''
    const initialValue = options.find(x => x.value === initialFilterValue.toString() || x.value === initialFilterValue || x.value === parseInt(initialFilterValue))
    return initialValue
}

const Select = ({ name, fieldName, values, control, helper, fieldAccessorMap, formWidth, formName }) => {
    const { params } = useContext(ParameterContext)
    const { selectDropDownCaratClass, tooltipClass } = useContext(PanelStyleContext)
    const options = mapValuesToOptions(values, fieldAccessorMap)
    const initialValue = getInitialValue(name, formName, params, options)

    return (
        <div className={`column ${formWidth === 'full' ? 'is-one-third' : 'is-full'} no-vertical-padding formInputWrapper`}>
            <div className="formInputLayout">
                <div className="field">
                    <div className="field-label is-small">
                        <label className="label">{fieldName}</label>
                    </div>
                    <div className="field-body">
                        <div className={`field ${helper ? 'has-addons' : ''}`}>
                            {helper && (
                                <div 
                                    data-tip={`${helper}`} 
                                    data-for={name}
                                    className="control formHelper"
                                >
                                    <span
                                        
                                        className="button is-static is-small"
                                    >
                                        <FaInfoCircle />
                                        <Tooltip id={name} className={tooltipClass} />
                                    </span>
                                </div>
                            )}
                            <div className={`control is-expanded ${selectDropDownCaratClass}`}>
                                <Controller
                                    as={
                                        <ReactSelect
                                            className="select is-small is-multiple is-fullwidth reactSelect"
                                            classNamePrefix="reactSelect"
                                            options={options}
                                            styles={SelectStyles}
                                            menuPlacement="auto"
                                            isClearable
                                            menuPosition={'fixed'}
                                        />
                                    }
                                    name={`${name}Select`}
                                    control={control}
                                    onChange={([selected]) => {
                                        // React Select return object instead of value for selection
                                        return { value: selected }
                                    }}
                                    defaultValue={
                                        initialValue ? initialValue : null
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Select
