import React, { useContext, useEffect } from 'react'

import AgencyConfirmation from '../AgencyConfirmation'
import SubmissionForm from './SubmissionForm'
import { DataContext } from '../DataContext'
import LoadingWrapper from '../../../wrappers/LoadingWrapper'

export default () => {
    const { activeAgency, createUpload, formType, isForminProgress, isNewForm } = useContext(DataContext)
    const isRestrictedToGEM = formType.FormType === `MIT` || formType.FormType === 'PressureVolume'
    
    useEffect(() => {
        if (isRestrictedToGEM && isNewForm && !isForminProgress) {
            createUpload('GEM')
        }
    }, [isNewForm, isForminProgress])

    return ( 
        isForminProgress 
        || isRestrictedToGEM ? (
            <LoadingWrapper data={activeAgency} >
                <SubmissionForm /> 
            </LoadingWrapper> 
        ) 
        : <AgencyConfirmation onSubmit={ createUpload } /> 
    )
}
