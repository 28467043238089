import React, { useContext, useMemo } from 'react'
import dayjs, { utc } from 'dayjs';

import { DataContext } from '../DataContext'
import Nav from './Nav'
import SubmitButton from './SubmitButton'
import LoadingWrapper from '../../../wrappers/LoadingWrapper'
import Content from './Content'
import Breadcrumbs from './Breadcrumbs'
import StatusBanner from '../StatusBanner'
import UnsavedChanges from '../UnsavedChanges'
import ContinueAnyway from '../ContinueAnyway'
import BeforePageChange from '../BeforePageChange'
import { useLocation } from 'react-router-dom'

dayjs.extend(utc)

export default () => {
    const { uploadConfig, isReview, viewOnly, submissionState, formType } = useContext(DataContext)
    const location = useLocation()

    const samplingPointIdentifiers = useMemo(() => {
        if (formType.FormType === 'SamplingPoint') {
            if (submissionState && submissionState.facility) {
                const RBDMSID = submissionState.facility.FacilityID  
                return { RBDMSID, }
            }
            // if (location.state && location.state.clearinghouse) {
            //     return location.state.clearinghouse
            // }
            return {}
        }
    }, [submissionState, location, formType])


    const mitIdentifiers = useMemo(() => {
        if (formType.FormType === 'MIT') {
            if (submissionState && submissionState.Agent) {
                const agent = submissionState.Agent 
                let status = agent.Status === '1' ? '- Active' : agent.Status === '2' ? '- Inactive' : ''
                return {
                    operator: `${submissionState.Agent.AgentName} ${status}`,
                    regularReporting: submissionState.Agent.MitregularReporting
                }
            }
            return {}
        }
    }, [submissionState, location, formType])

    const pressureVolumeIdentifiers = useMemo(() => {
        if (formType.FormType === 'PressureVolume') {
            if (submissionState && submissionState.MonitorReq && submissionState.Project) {
                const monitorReq = submissionState.MonitorReq
                let startDate = dayjs.utc(monitorReq.StartDate).format('MM/DD/YYYY')
                let endDate = dayjs.utc(monitorReq.EndDate).format('MM/DD/YYYY')
                let facilityNumber = submissionState.Project.Apinumber
                return {
                    startDate,
                    endDate,
                    facilityNumber
                }
            }
            return {}
        }
    }, [submissionState, location, formType])

    return (
        <div className="hero is-fullheight-with-navbar unset-justify-content">
            <Breadcrumbs />
            <div className="columns no-margin is-multiline">
                <StatusBanner uploadData={submissionState && submissionState.upload ? submissionState.upload : submissionState.UploadId ? submissionState : null} />
                    <div className='clearinghouseNumber'><p>
                            {submissionState && submissionState.Agent ? (<>
                                <b>Operator:</b> { mitIdentifiers.operator } &nbsp;&nbsp;&nbsp;&nbsp;
                                {mitIdentifiers.regularReporting !== null || mitIdentifiers.regularReporting !== undefined ? (<>
                                    <b>Regular Reporting?</b> { mitIdentifiers.regularReporting ? 'Yes' : 'No' }
                                </>) : null}
                            </>) : null}
                            {submissionState && submissionState.MonitorReq ? (<>
                                <b>Facility Number:</b> { pressureVolumeIdentifiers.facilityNumber } &nbsp;&nbsp;&nbsp;&nbsp;
                                <b>Reporting Period Start Date:</b> {pressureVolumeIdentifiers.startDate} &nbsp;&nbsp;&nbsp;&nbsp;
                                <b>Reporting Period End Date:</b> {pressureVolumeIdentifiers.endDate} &nbsp;&nbsp;&nbsp;&nbsp;
                            </>) : null}
                            {submissionState && submissionState.facility && (submissionState.facility.FacilityID || submissionState.facility.UploadFacilityID) ? (<>
                                <b>RBDMS ID:</b> { samplingPointIdentifiers.RBDMSID } 
                            </>) : null}
                    </p></div>
                <UnsavedChanges />
                <ContinueAnyway />
                <BeforePageChange />
                <div className="column is-12">
                    <div>
                        <div>
                            <div className="columns is-multiline">
                                <LoadingWrapper data={uploadConfig}>
                                    <div className="column is-10">
                                        <StatusBanner />
                                        <Content />
                                    </div>
                                    <div className="column is-2">
                                        <Nav />
                                        { !(isReview || viewOnly)  && <SubmitButton />}
                                    </div>
                                </LoadingWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
