import React from 'react'

export default ({ resetFunction, isInDropdown=false }) => {
    return (
        <div
            className={`panelButton ${ isInDropdown ? `timeWindowOptionWrapper` : `zoomButton` }`}
            onClick={() => resetFunction()}
        >
            <span className="timeWindowOptionText">Reset Zoom</span>
        </div>
    )
}