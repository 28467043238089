import React, { useContext } from 'react'

import Filter from './Filter'
import List from './List'
import DataContextProvider from './DataContext'
import Panel from '../../../elem/panel/Panel'
import WellHeader from './WellHeader'
import { wellPanel as panelClassConfig } from '../../../../utils/styles/panelStyles'
import { AppStateContext } from '../AppStateContext'


const Explorer = ({ width }) => {
    const {
        filterState,
        updateFilterState,
        syncFilterState,
        promotedRecords,
        togglePromotedRecords,
    } = useContext(AppStateContext)

    const [isCollapsed, setCollapsed] = React.useState(false)
    const filterExpanded = filterState['well']
    const toggleFilter = updateFilterState.bind(this, 'well')

    return (
        <Panel panelClassConfig={panelClassConfig(width)}>
            <div className="explorerWrapper">
                <DataContextProvider>
                    <WellHeader
                        filterExpanded={filterExpanded}
                        toggleFilter={toggleFilter}
                        promotedRecords={promotedRecords['Well']}
                        togglePromotedRecords={togglePromotedRecords}
                        setCollapsed={setCollapsed}
                        isCollapsed={isCollapsed}
                    />
                    {!isCollapsed && (<>
                        <Filter
                            width={width}
                            filterExpanded={filterExpanded}
                            syncFilterState={syncFilterState}
                        />
                        <List
                            promotedRecords={promotedRecords}
                            filterExpanded={filterExpanded}
                            toggleFilter={toggleFilter}
                        />
                    </>)}
                </DataContextProvider>
            </div>
        </Panel>
    )
}

export default Explorer
