import React, { useContext, useEffect } from 'react'

import AgencyConfirmation from '../AgencyConfirmation'
import EDDDialogue from './EDDDialogue'
import { DataContext } from '../DataContext'
import LoadingWrapper from '../../../wrappers/LoadingWrapper'

export default () => {
    const { activeAgency, setActiveAgency, formType } = useContext(DataContext)

    useEffect(() => {
        if (formType.FormType === `MIT` || formType.FormType === 'PressureVolume')
            setActiveAgency('GEM')
    }, [formType])

    return ( 
        activeAgency ? (
            <LoadingWrapper data={activeAgency} >
                <EDDDialogue /> 
            </LoadingWrapper> 
        )
        : <AgencyConfirmation onSubmit={setActiveAgency} /> 
    )
}

