import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react'

import withConfig from '../../../wrappers/withConfig'
import { AppStateContext } from '../AppStateContext'
import { ParameterContext } from '../../../wrappers/ParameterContext'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import { timeWindowOptions } from '../../../../utils/chart/timeWindow'
import toast from '../../../elem/Toast'
import { generateDateParams } from '../../../../utils/chart/values'
import { filterMultiSeriesTimeData, /*filterMultiSeriesTimePeriod*/  } from '../../../../utils/chart/timeWindow'

const DataContext = createContext(null)

export default withConfig(({ config, children }) => {
    const { mapState } = useContext(AppStateContext)
    const { params } = useContext(ParameterContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [chartData, setChartData] = useState([])
    const [analyteList, setAnalyteList] = useState([])
    const [tooManyFeatures, setTooManyFeatures] = useState(false)
    const [timeWindow, setTimeWindow] = useState(timeWindowOptions[0])
    const [displayTimeWindowDropdown, toggleTimeWindowDropdown] = useState(
        false
    )
    const [zoomTrigger, setZoomTrigger] = useState(false)
    const [selectedAnalytes, setSelectedAnalytes] = useState([])
    const [
        displayAnalyteWindowDropdown,
        toggleAnalyteWindowDropdown,
    ] = useState(false)

    const [selectedUnits, setSelectedUnits] = useState(null)

    const dateParams = useMemo(() => {
        const d = generateDateParams(params)
        return d.some(x => x !== null) ? d : null
    }, [params])

    const [loading, setLoading] = useState(false)
    const { selectedFeatures } = mapState
    const { API_URL } = config

    const [isCollapsed, setCollapsed] = React.useState(false)
    const [resetExpanded, toggleResetExpanded] = useState(false)

    const fetchAnalyteList = useCallback(() => {
        return new Promise(async (resolve, reject) => {

            // get the date range that exists
            const [startDate, endDate] = generateDateParams(params)

            // get the ids of the selected features
            const facilityIDs = encodeURI(
                selectedFeatures.map(x => x.get('FacilityID')).toString()
            )
            
            // construct body of post request
            const body = JSON.stringify({
                facilityIDs: facilityIDs,
                StartDate: startDate,
                EndDate: endDate,
            })

            await authenticatedFetch(`${API_URL}/aggregate/getAnalyteList`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers':
                        'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                },
                body,
            })
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setAnalyteList(response.analyteListData)
                return resolve(response.analyteListData)
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Sample List: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
                return reject()
            })
        })
    }, [selectedFeatures, params])

    const fetchChartData = useCallback((analyteList) => {
        return new Promise(async (resolve, reject) => {

            const [startDate, endDate] = generateDateParams(params)
            const facilityIDs = encodeURI(
                selectedFeatures.map(x => x.get('FacilityID')).toString()
            )
            const paramIDs =
                analyteList && analyteList.length && !analyteList.every(x => x === undefined) 
                ? encodeURI(analyteList.map(x => x.ParamID).toString()) 
                : ''
    
            const body = JSON.stringify({
                facilityIDs,
                paramIDs,
                StartDate: startDate,
                EndDate: endDate,
            })
            await authenticatedFetch(`${API_URL}/aggregate`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers':
                        'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                },
                body,
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setChartData(response.chartData)
                    return resolve(response.chartData)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                        'Sample List: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                        })
                    return reject()
                })
        })
    }, [selectedFeatures])
     
    const updateSelectedAnalytes = useCallback(analyteList => {
        // if there are selected analytes and all of them are in the new list of analytes,
        // do not update the analyte list
        if (
            selectedAnalytes.length && 
            selectedAnalytes.every(x => analyteList.map(y => y.ParamID).includes(x.ParamID))
        ) {
            return
        } else {
            // otherwise, update the analyte list with the first entry in the list
            const firstAnalyte = analyteList[0]
            setSelectedAnalytes([firstAnalyte])
            return
        }
    }, [selectedAnalytes, setSelectedAnalytes])

    // when the selected features change or the time window changes, go fetch the list of
    // available analytes
    useEffect(() => {
        if (selectedFeatures.length) {
            // SQL Server has a limit of 2100 parameters that can be supplied
            // to a query. if there is a workaround, this check can be removed
            if (selectedFeatures.length < 2100) {
                setLoading(true)
                setTooManyFeatures(false)
                fetchAnalyteList().then(async analyteList => {
                    updateSelectedAnalytes(analyteList)
                    setLoading(false)
                    return
                })
                .catch(e => setLoading(false))
            } else {
                setTooManyFeatures(true)
            }
        } else {
            // if there are no features,
            // set data to an empty array
            setChartData([])
            setAnalyteList([])
            setSelectedAnalytes([])
        }
    }, [selectedFeatures, dateParams])

    // when the selected analytes change, go fetch the new chart data
    useEffect(() => {
        if (selectedAnalytes && selectedAnalytes.length) {
            setLoading(true)
            fetchChartData(selectedAnalytes)
                .then(() => setLoading(false))
                .catch(e => setLoading(false))
        } else {
            setChartData([])
        }
    }, [selectedAnalytes])

    const analyteChartData = useMemo(() => {
        if (chartData && chartData.length) {
            // console.log('updating analytechartdata', dateParams, timeWindow)
            return dateParams
                ? chartData
                // ? filterMultiSeriesTimePeriod(
                //       chartData,
                //       dateParams,
                //       'DateString'
                //   )
                : filterMultiSeriesTimeData(
                      chartData,
                      timeWindow,
                      'DateString'
                  )
        }
        return []
    }, [chartData, timeWindow, dateParams])

    const toggleSelectedAnalyte = useCallback(
        analyte => {
            if (selectedAnalytes.find(x => x.ParamID === analyte.ParamID)) {
                setSelectedAnalytes(
                    selectedAnalytes.filter(x => x.ParamID !== analyte.ParamID)
                )
            } else {
                if (selectedAnalytes.length < 5) {
                    const unit = analyte.Units
                    if (selectedUnits && unit !== selectedUnits) {
                        toast({
                            level: 'info',
                            message: `Please select an analyte that has the same units (${selectedUnits}) as the other analytes in the chart.`,
                            alert: true,
                        })
                    } else {
                        setSelectedAnalytes(selectedAnalytes.concat(analyte))
                    }
                } else {
                    toast({
                        level: 'info',
                        message:
                            'Too many analytes selected. Select up to 5 analytes to display.',
                        alert: true,
                    })
                }
            }
        },
        [selectedAnalytes, selectedUnits]
    )

    useEffect(() => {
        if (selectedAnalytes && selectedAnalytes.length && !selectedAnalytes.every(x => x === undefined)) {
            setSelectedUnits(selectedAnalytes[0].Units)
        } else {
            setSelectedUnits(null)
        }
    }, [selectedAnalytes])

    const resetAnalytes = useCallback(() => {
        const firstAnalyte = analyteList[0]
        if (firstAnalyte) {
            setSelectedAnalytes([firstAnalyte])
        } else {
            setSelectedAnalytes([])
        }
    }, [analyteList])

    const resetZoom = useCallback(() => {
        setZoomTrigger(!zoomTrigger)
    }, [zoomTrigger])

    return (
        <DataContext.Provider
            value={{
                loading,
                chartData,
                tooManyFeatures,
                timeWindow,
                setTimeWindow,
                displayTimeWindowDropdown,
                toggleTimeWindowDropdown,
                selectedAnalytes,
                setSelectedAnalytes,
                toggleSelectedAnalyte,
                toggleAnalyteWindowDropdown,
                displayAnalyteWindowDropdown,
                selectedUnits,
                analyteChartData,
                analyteList,
                resetAnalytes,
                resetZoom,
                zoomTrigger,
                isCollapsed,
                setCollapsed,
                resetExpanded,
                toggleResetExpanded,
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }
