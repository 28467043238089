import React, { useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'
import urls from '../../../../utils/constants/urls'

import { DataContext } from '../DataContext'

export default () => {
    const { returnToList } = useContext(DataContext)
    const history = useHistory()

    const onClick = useCallback(() => {
        returnToList()
            .then(() => {
                history.push(urls.manageSampleResults)
            })
            .catch(() => {
            })
    }, [returnToList])

    return (
        <nav
            className="breadcrumb breadcrumbAlignment"
            aria-label="breadcrumbs"
        >
            <ul>
                <li>
                    <div className="link"
                        onClick={onClick}
                    >
                        <span className="icon is-small">
                            <FaChevronLeft />
                        </span>
                        <span>Return to Submissions List</span>
                    </div>
                </li>
            </ul>
        </nav>
    )
}
