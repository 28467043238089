import React, { useContext, useEffect } from 'react'

// import TimeWindowSelectorButton from '../../../../elem/chart/TimeWindowSelectorButton'
import AnalyteSelectorButton from '../../../../elem/chart/AnalyteSelectorButton'
import MeasureSelectorButton from '../../../../elem/chart/MeasureSelector/MeasureSelectorButton'
import ResetDropDownButton from '../../../../elem/chart/ResetDropDownButton'

import { DataContext } from './../DataContext'
import SelectorButton from '../../../../elem/chart/SelectorButton'

const ChartControls = () => {
    const {
        // timeWindow,
        // displayTimeWindowDropdown,
        toggleTimeWindowDropdown,
        displayAnalyteWindowDropdown,
        toggleAnalyteWindowDropdown,
        visibleChartTab,
        measureResetExpanded,
        toggleMeasureResetExpanded,
        analyteResetExpanded,
        toggleAnalyteResetExpanded,
        displayMeasureWindowDropdown,
        toggleMeasureWindowDropdown, 
        displayGroupingWindowDropdown,
        toggleGroupingWindowDropdown,
    } = useContext(DataContext)
    
    useEffect(() =>{ //if the current tab is not the pressure tab then hide the selector, else don't change notin'  
        if (visibleChartTab !== 'pressure') {
            toggleMeasureResetExpanded(false)
        }
        if (visibleChartTab !== 'sampleResult') {
            toggleTimeWindowDropdown(false)
        }
    }, [visibleChartTab])
    
    return (
        <div className="chartControlsWrapper">
            {
                visibleChartTab === 'sampleResult' 
                ? (
                    <>
                        <ResetDropDownButton 
                            inHeader={false}
                            resetExpanded={analyteResetExpanded}
                            toggleResetExpanded={toggleAnalyteResetExpanded} 
                        />
                        <AnalyteSelectorButton
                            inHeader={false} 
                            displayAnalyteWindowDropdown={displayAnalyteWindowDropdown}
                            toggleAnalyteWindowDropdown={toggleAnalyteWindowDropdown}
                        />
                        {/* <TimeWindowSelectorButton
                            inHeader={false} 
                            timeWindow={timeWindow}
                            displayTimeWindowDropdown={displayTimeWindowDropdown}
                            toggleTimeWindowDropdown={toggleTimeWindowDropdown}
                        /> */}
                    </>
                ) 
                : (
                    <>
                        <ResetDropDownButton 
                            inHeader={false}
                            resetExpanded={measureResetExpanded}
                            toggleResetExpanded={toggleMeasureResetExpanded} 
                        />
                        <SelectorButton 
                            inHeader={false}
                            displayWindowDropdown={displayGroupingWindowDropdown}
                            toggleWindowDropdown={toggleGroupingWindowDropdown}
                            label={`Report Grouping`}
                        />
                        <MeasureSelectorButton 
                            inHeader={false}
                            displayMeasureWindowDropdown={displayMeasureWindowDropdown}
                            toggleMeasureWindowDropdown={toggleMeasureWindowDropdown}
                        /> 
                    </>
            )}
        </div>
    )
}

export default ChartControls