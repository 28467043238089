import React from 'react'
import { FaSlidersH, FaThList, FaArrowAltCircleUp, FaRegArrowAltCircleUp, FaAngleUp, FaAngleDown  } from 'react-icons/fa'

import withConfig from '../../../wrappers/withConfig'
import PanelHeader from '../../../elem/panel/PanelHeader'
import { IconTooltip as Tooltip } from '../../../elem/Tooltip'

export default withConfig(
    ({
        filterExpanded,
        toggleFilter,
        togglePromotedRecords,
        promotedRecords,
        config,
        setCollapsed,
        isCollapsed
    }) => {
        const promotedRecordsFieldName = 'Well'
        const { FACILITY_TITLE: facilityTitle } = config
        return (
            <PanelHeader extraClass={'wellPanelHeader'}>
                <div className="panelHeaderText">{facilityTitle} Explorer</div>
                <div className="panelHeaderControls">
                    <div className="panelHeaderIconWrapper">
                        {!filterExpanded && <div
                            className="panelHeaderIconDiv"
                            data-tip={`${
                                promotedRecords
                                    ? `Do not promote Selected Records`
                                    : `Promote  Selected Records`
                            }`}
                            data-for={`well-promoted-records-toggle`}
                            onClick={() => togglePromotedRecords(!promotedRecords, promotedRecordsFieldName)}
                        >
                            {promotedRecords ? <FaArrowAltCircleUp /> : <FaRegArrowAltCircleUp />}
                            <Tooltip id={'well-promoted-records-toggle'} />
                        </div>}
                        <div
                            className="panelHeaderIconDiv"
                            data-tip={`${
                                filterExpanded
                                    ? `View ${facilityTitle} List`
                                    : `View ${facilityTitle} Filter`
                            }`}
                            data-for={`well-view-toggle`}
                            onClick={() => toggleFilter(!filterExpanded)}
                        >
                            {filterExpanded ? <FaThList /> : <FaSlidersH />}
                            <Tooltip id={'well-view-toggle'} />
                        </div>
                        <Tooltip
                            id={'well-export-button'}
                            className={'is-white'}
                        />
                        <div
                        className="panelHeaderIconDiv"
                        data-tip={`${
                            isCollapsed
                                ? `Expand Explorer`
                                : `Hide Explorer`
                        }`}
                        data-for={`well-panel-toggle`}
                        onClick={() =>
                            setCollapsed(!isCollapsed)
                        }
                    >
                        {isCollapsed ? (
                            <FaAngleUp />
                        ) : (
                            <FaAngleDown />
                        )}
                        <Tooltip
                            id={`well-panel-toggle`}
                        />
                    </div>
                    </div>
                </div>
            </PanelHeader>
        )
    }
)
