import React, { useContext } from 'react'

import { FacilityAssignmentMITContext } from './DataContext'
import { IconTooltip as Tooltip } from '../../../../elem/Tooltip'
import withConfig from '../../../../wrappers/withConfig'
import SaveButton from '../SaveButton'
import ResetButton from '../ResetButton'

const IconWrapper = ({ onClick, tooltipText, name, extraClass, children }) => {
    return (
        <div
            className="assignmentActionButton"
            onClick={onClick}
            data-tip={`${tooltipText}`}
            data-for={`${name}`}
        >
            <button className={`button ${extraClass}`}>
                {children}
            </button>
            <Tooltip id={name} />
        </div>
    )
}

const updateWithHighlightedFeatures = (
    highlightedFeatures,
    updateHighlightedFeatures,
    source,
    updateSource,
    target,
    updateTarget,
    matchColumn
) => {
    // update the target with the target + source data
    updateTarget([...target, ...highlightedFeatures])

    // filter out the highlightedFeatures from the source
    const highlightedIds = highlightedFeatures.map(x => x[matchColumn])
    const updatedSource = source.filter(
        x => !highlightedIds.includes(x[matchColumn])
    )
    updateSource(updatedSource)

    // now that we have updated state, clear the highlighted features
    updateHighlightedFeatures([])
    return
}

const updateAll = (
    source,
    updateSource,
    target,
    updateTarget,
    updateHighlightedFeatures
) => {
    updateTarget([...target, ...source])
    updateSource([])
    updateHighlightedFeatures([])
}

const AssignAll = () => {
    const {
        possibleFacilityData,
        setPossibleFacilityData,
        assignedFacilityData,
        setAssignedFacilityData,
        setHighlightedPossibleFacilities
    } = useContext(FacilityAssignmentMITContext)

    const onClick = () => {
        // assign all possible facilities to the project
        updateAll(
            possibleFacilityData,
            setPossibleFacilityData,
            assignedFacilityData,
            setAssignedFacilityData,
            setHighlightedPossibleFacilities
        )
    }
    return (
        <IconWrapper
            name="assignAll"
            tooltipText="Assign All"
            onClick={onClick}
            extraClass={'is-primary'}
        >
            {`>>`}
        </IconWrapper>
    )
}

const AssignSelected = ({ matchColumn }) => {
    const {
        highlightedPossibleFacilities,
        setHighlightedPossibleFacilities,
        possibleFacilityData,
        setPossibleFacilityData,
        assignedFacilityData,
        setAssignedFacilityData,
    } = useContext(FacilityAssignmentMITContext)

    const onClick = () => {
        // assign highlighted facilities to the project
        updateWithHighlightedFeatures(
            highlightedPossibleFacilities,
            setHighlightedPossibleFacilities,
            possibleFacilityData,
            setPossibleFacilityData,
            assignedFacilityData,
            setAssignedFacilityData,
            matchColumn
        )
    }

    return (
        <IconWrapper
            name="assignSelected"
            tooltipText="Assign Selected"
            onClick={onClick}
            extraClass={`is-primary`}
        >
            {`>`}
        </IconWrapper>
    )
}

const UnassignSelected = ({ matchColumn }) => {
    const {
        highlightedAssignedFacilities,
        setHighlightedAssignedFacilities,
        possibleFacilityData,
        setPossibleFacilityData,
        assignedFacilityData,
        setAssignedFacilityData,
    } = useContext(FacilityAssignmentMITContext)

    const onClick = () => {
        updateWithHighlightedFeatures(
            highlightedAssignedFacilities,
            setHighlightedAssignedFacilities,
            assignedFacilityData,
            setAssignedFacilityData,
            possibleFacilityData,
            setPossibleFacilityData,
            matchColumn
        )
    }

    return (
        <IconWrapper
            name="unassignSelected"
            tooltipText="Unassign Selected"
            onClick={onClick}
            extraClass="is-danger"
        >
                {`<`}
        </IconWrapper>
    )
}

const UnassignAll = () => {
    const {
        possibleFacilityData,
        setPossibleFacilityData,
        assignedFacilityData,
        setAssignedFacilityData,
        setHighlightedAssignedFacilities
    } = useContext(FacilityAssignmentMITContext)

    const onClick = () => {
        updateAll(
            assignedFacilityData,
            setAssignedFacilityData,
            possibleFacilityData,
            setPossibleFacilityData,
            setHighlightedAssignedFacilities
        )
    }

    return (
        <IconWrapper
            name="unassignAll"
            tooltipText="Unassign All"
            onClick={onClick}
            extraClass="is-danger"
        >
            {`<<`}
        </IconWrapper>
    )
}

export default withConfig(({ config }) => {
    const { ID_COLUMN } = config

    return (
        <div className="assignmentActionsPositioning">
            <div className="assignmentActionsWrapper">
                <AssignAll />
                <AssignSelected matchColumn={ID_COLUMN} />
                <UnassignSelected matchColumn={ID_COLUMN} />
                <UnassignAll />
                <div className="assignmentSaveAndContinueWrapper">
                    <div className="assignmentActionButton">
                        <SaveButton formId="assigned-facilities-mit" />
                    </div>
                    <div className="assignmentActionButton">
                        <ResetButton formId="assigned-facilities-mit" />
                    </div>
                </div>
            </div>
        </div>
    )
})
