const customStyles = {
    control: provided => ({
        ...provided,
        minHeight: '2.3em',
    }),
    indicatorsContainer: provided => ({
        ...provided,
        height: '2.3em',
    }),
    valueContainer: provided => ({
        ...provided,
        minHeight: '2.3em',
        padding: '1px 8px',
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: '2px',
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: '2px',
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 })
}

export default customStyles
