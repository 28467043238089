import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import dayOfYear from 'dayjs/plugin/dayOfYear'
dayjs.extend(dayOfYear)
dayjs.extend(quarterOfYear)

const format = "MM/DD/YYYY"
const dateToString = date => date ? dayjs(date).format(format).toString() : null
const stringToDate = date => date ? dayjs(date).toDate() : null

const validateReportingPeriod = (reportingPeriodFrequency, reportingPeriodStartDate, reportingPeriodEndDate) => {
    // monthly reporting period
    let errors = {}
    const startDate = reportingPeriodStartDate ? dayjs(reportingPeriodStartDate) : null
    const endDate = reportingPeriodEndDate ? dayjs(reportingPeriodEndDate) : null
    if (startDate) {
        if (endDate && endDate.year() !== startDate.year()) {
            errors.endDate = 'Reporting Period Start Date and Reporting Period End Date must be in the same year' 
            return errors
        }

        if (reportingPeriodFrequency === 'M') {
            // ensure that reportingPeriodStartDate starts on the first day of the month
            if (startDate.date() !== 1) {
                errors.startDate = 'Reporting Period Start Date must be the first day of the month'
            }
    
            // ensure that reportingPeriodEndDate ends on the last day of the selected month
            if (endDate && (endDate.year() !== startDate.year() || endDate.month() !== startDate.month() || endDate.date() !== endDate.daysInMonth())) {
                errors.endDate = 'Reporting Period End Date must be the last day of the Start Date’s month'
            }
        }
        if (reportingPeriodFrequency === 'Q') {
            // ensure that the reportingPeriodStartDate starts on the first day of the quarter
            if (startDate.dayOfYear() !== startDate.startOf('quarter').dayOfYear()) {
                errors.startDate = 'Reporting Period Start Date must be 01/01, 04/01, 07/01, or 10/01'
            }
    
            // ensure that the reportingPeriodEndDate ends on the last day of the quarter
            if (endDate && (endDate.year() !== startDate.year() || endDate.dayOfYear() !== startDate.endOf('quarter').dayOfYear())) {
                errors.endDate = 'Reporting Period End Date must be the last day of the Start Date’s quarter (03/31, 06/30, 09/30, or 12/31)'
            }
        }
        if (reportingPeriodFrequency === 'S') {
            // ensure that the reportingPeriodStartDate starts on the first day of the semi-annual period
            const isInvalidStartDate = startDate.dayOfYear() !== startDate.startOf('year').dayOfYear() && startDate.dayOfYear() !== startDate.startOf('year').subtract(6, 'month').dayOfYear()
            if (isInvalidStartDate) {
                errors.startDate = 'Reporting Period Start Date must be 01/01 or 07/01'
            }
            
            // ensure that the reportingPeriodEndDate ends on the last day of the semi-annual period
            const semiAnnualEnd = startDate.startOf('year').add(6, 'month').subtract(1, 'day')
            const annualEnd = startDate.endOf('year')
            if (!isInvalidStartDate && endDate 
                && (
                    (endDate.year() !== startDate.year() || endDate.dayOfYear() !== semiAnnualEnd.dayOfYear())
                    && (endDate.year() !== startDate.year() || endDate.dayOfYear() !== annualEnd.dayOfYear())
                )
            ) {
                errors.endDate = 'Reporting Period End Date must be the last day of the Start Date’s semiannual period (06/30 or 12/31)'
            }
    
        }
        if (reportingPeriodFrequency === 'A') {
            // ensure that the reportingPeriodStartDate starts on the first day of the year
            if (startDate.dayOfYear() !== startDate.startOf('year').dayOfYear()) {
                errors.startDate = 'Reporting Period Start Date must be 01/01'
            }
    
            // ensure that the reportingPeriodEndDate ends on the last day of the year
            if (endDate && (endDate.year() !== startDate.year() || endDate.dayOfYear() !== startDate.endOf('year').dayOfYear())) {
                errors.endDate = 'Reporting Period End Date must be 12/31'
            }
        }
    }
    return errors
}

export { dateToString, stringToDate, validateReportingPeriod }