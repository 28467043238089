import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
    useMemo,
} from 'react'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'

import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
import { DataContext } from '../../DataContext'
import SelectStyles from '../../../../elem/form/SelectStyles'
import withConfig from '../../../../wrappers/withConfig'
import toast from '../../../../elem/Toast'
import { FaTimes } from 'react-icons/fa'
import { validateReportingPeriod } from '../../../../../utils/dateUtils'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

const ExistingSearchComponent = withConfig(
    ({
        config,
        setAliasStateData,
        targetField,
        setTargetField,
        setContinueDisabled,
        setProjectChanged
    }) => {
        const { authenticatedFetch } = useContext(APIRequestContext)
        const { activeAgency, setFormMethods, formType } = useContext(
            DataContext
        )
        const formMethods = useForm({ mode: 'onChange' })
        const [loading, setLoading] = useState(false)
        const [value, setValue] = useState('')
        const [valuesChanged, setValuesChanged] = useState(false)
        const [error, setError] = useState(null)
        const [warning, setWarning] = useState(null)
        const { register, handleSubmit } = formMethods
        const searchDisabled = useMemo(() => !value, [value])
        const { API_URL } = config
        
        useEffect(() => {
            if (value !== '' && !valuesChanged) {
                setProjectChanged(false)
            } else {
                setContinueDisabled(true)
                setProjectChanged(true)
                //clear errors if project changes
                setWarning(null)
                setError(null)
            }
        }, [valuesChanged, value])

        useEffect(() => {
            setFormMethods(formMethods)
        }, [])

        const search = (v, field) => {
            if (v === '') {
                setError('Please enter a value.')
                setAliasStateData({})
                return
            }
            if (field === '' || field === null) {
                setError(
                    `Please select an identifier for the existing ${formType.Singular}.`
                )
                setAliasStateData({})
                return
            }
            setLoading(true)
            setAliasStateData({})
            authenticatedFetch(
                `${API_URL}/upload/${formType.APILink}/${formType.APILink}Info?columnName=${field}&columnValue=${v}&agencyCode=${activeAgency}`
            )
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setAliasStateData(response[`projectInfo`])
                    setWarning(
                        response['projectInfo'] &&
                            response['projectInfo']['warning']
                            ? response['projectInfo']['warning']
                            : null
                    )
                    setError(null)
                    setValuesChanged(false)
                })
                .catch(e => {
                    setError(e.message)
                    setWarning(null)
                    setAliasStateData({})
                })
                .finally(() => setLoading(false))
        }

        return (
            <form
                className="form"
                onSubmit={handleSubmit(() => search(value, targetField))}
            >
                <div className="field is-horizontal is-align-items-center is-full-width">
                    <div className="field-label">
                        <label className="label">{`Facility Number:`}</label>
                    </div>
                    <div className="field-body">
                        <div className="field is-grouped">
                            <p className="control is-expanded">
                                <input
                                    className={`input is-fullwidth`}
                                    onChange={e => {
                                        setValuesChanged(true)
                                        setValue(e.target.value)
                                    }}
                                    value={value}
                                    ref={register}
                                    name="columnValue"
                                />
                            </p>
                            <p className="control">
                                <button
                                    type="submit"
                                    disabled={`${
                                        searchDisabled ? 'true' : ''
                                    }`}
                                    className={`button is-primary ${
                                        loading ? 'is-loading' : ''
                                    }`}
                                >
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
                {warning ? (
                    <div className="has-text-centered darkish-orange">
                        {warning}
                    </div>
                ) : null}
                {error ? (
                    <div className="has-text-centered has-text-danger">
                        {error}
                    </div>
                ) : null}
            </form>
        )
    }
)

const ProjectInformation = ({
    aliasStateData,
    duplicateAliasError,
    continueDisabled,
    viewOnly,
    projectChanged
}) => {
    const projectData = aliasStateData
    const [alias, setAlias] = useState()

    useEffect(() => {
        setAlias(
            projectData && projectData.projectAlias
                ? projectData.projectAlias.Alias
                : ''
        )
    }, [projectData])

    if (
        (projectChanged && (!viewOnly)) ||
        !(
            projectData.project &&
            Object.keys(projectData.project).some(
                x => projectData.project[x] != null
            )
        )
    ) {
        return null
    }

    return projectData && projectData.project ? (
        <form className="form is-centered">
            <div className="is-size-4 has-text-weight-semibold has-text-centered matchingFacilityText">
                Facility Information:{' '}
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Facility Number: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    projectData && projectData.project
                                        ? projectData.project.APINumber
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Facility Name: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    projectData && projectData.project
                                        ? projectData.project.ProjectName
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Facility Type: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    projectData && projectData.project
                                        ? projectData.project.ProjectTypeString
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    ) : null
}

const SaveAndContinueButton = withConfig(({
    config,
    aliasStateData,
    duplicateAliasError,
    continueDisabled,
}) => {
    const {
        setSubmissionState,
        submissionState,
        isSubmitting,
        setTableData,
        formType,
    } = useContext(DataContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { API_URL } = config

    const mergeData = useCallback(() => {
        if (duplicateAliasError) {
            toast({
                level: 'error',
                message: 'Please fix errors before continuing.',
                alert: true,
            })
        } else {
            setSubmissionState(prevSubmissionState => {
                return {
                    ...prevSubmissionState,
                    new: Math.random() * 1000,
                    ...aliasStateData,
                }
            })
            setTableData({})
        }
    }, [aliasStateData, duplicateAliasError])

    const checkReportingPeriod = useCallback(() => {
        setLoading(true)
        authenticatedFetch(
            `${API_URL}/upload/${formType.APILink}/validateReportingPeriod?projectId=${aliasStateData.project.ProjectID}&startDate=${encodeURIComponent(dayjs(aliasStateData.MonitorReq.StartDate).format('MM/DD/YYYY'))}&endDate=${encodeURIComponent(dayjs(aliasStateData.MonitorReq.EndDate).format('MM/DD/YYYY'))}`
        )
            .then(async response => {
                if (response.ok) {
                    setError(null)
                    //parse the monitorReq dates to a timezone-less date 
                    aliasStateData.MonitorReq.StartDate = dayjs(aliasStateData.MonitorReq.StartDate).format('YYYY-MM-DD[T]hh:mm:ss')
                    aliasStateData.MonitorReq.EndDate = dayjs(aliasStateData.MonitorReq.EndDate).format('YYYY-MM-DD[T]hh:mm:ss')
                    mergeData()
                    return
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .catch(e => {
                setError(e.message)
            })
            .finally(() => setLoading(false))
    }, [aliasStateData, submissionState, mergeData])

    useEffect(() => {
        if (continueDisabled) {
            setError(null)
        }
    }, [continueDisabled])

    return (
        <>
            {error ? 
                <p className="is-full has-text-centered has-text-danger">
                    {error}
                </p> : null 
            }
            <div className="buttonWrapper">
                <button
                    type="button"
                    onClick={() => checkReportingPeriod()}
                    disabled={`${continueDisabled ? 'true' : ''}`}
                    className={`button is-medium is-link ${
                        loading || isSubmitting ? 'is-loading' : ''
                    }`}
                >
                    {`Continue`}
                </button>
            </div>
                
        </>
    )
})

const ReportingPeriodForm = withConfig(
    ({
        config,
        aliasStateData,
        setAliasStateData,
        targetField,
        setTargetField,
        continueDisabled,
        setContinueDisabled,
        projectChanged,
        viewOnly
    }) => {
        const projectData = aliasStateData
        const { authenticatedFetch } = useContext(APIRequestContext)
        const { setFormMethods, formType } = useContext(
            DataContext
        )
        const formMethods = useForm({ mode: 'onChange' })
        const [valuesChanged, setValuesChanged] = useState(false)
        const [errors, setErrors] = useState({})
        const [warning, setWarning] = useState(null)
        const { handleSubmit } = formMethods
        const { API_URL } = config
        const [options, setOptions] = useState([])
        const [reportingPeriodFrequency, setReportingPeriodFrequency] = useState(null)
        const [reportingPeriodStartDate, setReportingPeriodStartDate] = useState(null)
        const [reportingPeriodEndDate, setReportingPeriodEndDate] = useState(null)

        useEffect(() => {
            authenticatedFetch(
                `${API_URL}/upload/${formType.APILink}/getReportingPeriods`
            )
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setOptions(response.reportingPeriods)
                })
                .catch(e => {
                })
        }, [])

        useEffect(() => {
            setFormMethods(formMethods)
        }, [])

        useEffect(() => {
            setReportingPeriodStartDate(null)
            setReportingPeriodEndDate(null)
        }, [reportingPeriodFrequency])

        useEffect(() => {
            setReportingPeriodFrequency(null)
            setReportingPeriodStartDate(null)
            setReportingPeriodEndDate(null)
        }, [projectChanged])

        useEffect(() => {
            const dateErrors = validateReportingPeriod(reportingPeriodFrequency, reportingPeriodStartDate, reportingPeriodEndDate)
            setErrors(dateErrors)
            setAliasStateData(prevAliasStateData => ({
                ...prevAliasStateData,
                MonitorReq: {
                    StartDate: reportingPeriodStartDate,
                    EndDate: reportingPeriodEndDate,
                    Frequency: reportingPeriodFrequency,
                    ProjectID: prevAliasStateData.project ? prevAliasStateData.project.ProjectID : null
                }
            }))
            if (reportingPeriodStartDate && reportingPeriodEndDate && reportingPeriodFrequency && !Object.keys(dateErrors).length) {
                setContinueDisabled(false)   
            } else {
                setContinueDisabled(true)
            }
        }, [valuesChanged, reportingPeriodEndDate, reportingPeriodStartDate, reportingPeriodFrequency, setErrors])

        return projectData && projectData.project && !projectChanged? (
            <form className="form" onSubmit={handleSubmit(() => {})}>
                <div className="columns">
                    <div className="column is-4">
                        <div className="field is-horizontal is-align-items-center">
                            <div className="field-label">
                                <label className="label">{`Reporting Period Interval:`}</label>
                            </div>
                            <div className="field-body">
                                <div className="field is-grouped">
                                    <p className="control is-expanded">
                                        <Select
                                            className="select is-small is-multiple is-fullwidth reactSelect"
                                            classNamePrefix="reactSelect"
                                            // inputRef={ref}
                                            options={options}
                                            styles={SelectStyles}
                                            menuPlacement="auto"
                                            name="reportingPeriod"
                                            // defaultValue={[{ value: true, label: 'Yes' }]}
                                            onChange={data => {
                                                setValuesChanged(true)
                                                setReportingPeriodFrequency(data.value)
                                            }}
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-4">
                        <div className="field is-horizontal is-align-items-center">
                            <div className="field-label">
                                <label className="label">{`Reporting Period Start Date:`}</label>
                            </div>
                            <div className="field-body">
                                <div className="field is-grouped">
                                    <p className="control is-expanded has-icons-right">
                                        <DatePicker
                                            className="input is-small"
                                            maxDate={new Date()}
                                            dateFormat="MM/dd/yyyy"
                                            selected={reportingPeriodStartDate}
                                            disabled={!reportingPeriodFrequency}
                                            showYearDropdown
                                            showMonthDropdown
                                            yearDropdownItemNumber={150}
                                            scrollableYearDropdown
                                            autoComplete="off"
                                            name={'frequency'}
                                            onChange={(value) => {
                                                setValuesChanged(true)
                                                setReportingPeriodStartDate(value)
                                            }}
                                        />
                                        <span
                                            className="icon is-right is-size-7"
                                            onClick={() => {
                                                setValuesChanged(true)
                                                setReportingPeriodStartDate(null)
                                            }}
                                        >
                                            <FaTimes />
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {errors.startDate ? (
                            <div className="help has-text-danger">
                                {errors.startDate}
                            </div>
                        ) : null}
                    </div>
                    <div className="column is-4">
                        <div className="field is-horizontal is-align-items-center">
                            <div className="field-label">
                                <label className="label">{`Reporting Period End Date:`}</label>
                            </div>
                            <div className="field-body">
                                <div className="field is-grouped">
                                    <div className="control is-expanded has-icons-right">
                                        <DatePicker
                                            className="input is-small"
                                            maxDate={new Date()}
                                            dateFormat="MM/dd/yyyy"
                                            selected={reportingPeriodEndDate}
                                            showYearDropdown
                                            showMonthDropdown
                                            disabled={!reportingPeriodStartDate}
                                            yearDropdownItemNumber={150}
                                            scrollableYearDropdown
                                            autoComplete="off"
                                            name={'frequency'}
                                            onChange={(value) => {
                                                setValuesChanged(true)
                                                setReportingPeriodEndDate(value)
                                            }}
                                        />
                                        <span
                                            className="icon is-right is-size-7"
                                            onClick={() => {
                                                setValuesChanged(true)
                                                setReportingPeriodEndDate(null)
                                            }}
                                        >
                                            <FaTimes />
                                        </span>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                        {errors.endDate ? (
                            <div className="help has-text-danger">
                                {errors.endDate}
                            </div>
                        ) : null}
                    </div>
                </div>                    
                {warning ? (
                    <div className="has-text-centered has-text-warning">
                        {warning}
                    </div>
                ) : null}
            </form>
        ) : null
    }
)

const ProjectSearchForm = ({ formType }) => {
    const { activePanel, submissionState, viewOnly, isReview } = useContext(
        DataContext
    )
    const [continueDisabled, setContinueDisabled] = useState(true)
    const [targetField, setTargetField] = useState('APINumber')
    const [aliasStateData, setAliasStateData] = useState(submissionState)
    const [duplicateAliasError] = useState(null)
    const [projectChanged, setProjectChanged] = useState(true)

    useEffect(() => {
        setAliasStateData(submissionState)
    }, [submissionState])

    const InformationComponent = React.createElement(ProjectInformation, {
        viewOnly,
        continueDisabled,
        aliasStateData,
        duplicateAliasError,
        targetField,
        projectChanged
    })

    if (viewOnly || isReview) {
        return (
            <div
                className={`column is-12 ${
                    activePanel !== `Facility Search` ? 'is-hidden' : ''
                }`}
            >
                <div className="columns is-centered">
                    <div className="column is-6">{InformationComponent}</div>
                </div>
            </div>
        )
    }
    return (
        <div
            className={`columns is-multiline ${
                activePanel !== `Facility Search` ? 'is-hidden' : ''
            }`}
        >
            <div className="column is-12">
                <ExistingSearchComponent
                    setAliasStateData={setAliasStateData}
                    targetField={targetField}
                    setTargetField={setTargetField}
                    setContinueDisabled={setContinueDisabled}
                    setProjectChanged={setProjectChanged}
                />
            </div>
            <div className="column is-12">
                <div className="columns is-centered">
                    <div className="column is-6">{InformationComponent}</div>
                </div>
            </div>
            <div className="column is-12">
                <ReportingPeriodForm
                    setAliasStateData={setAliasStateData}
                    aliasStateData={aliasStateData}
                    setContinueDisabled={setContinueDisabled}
                    viewOnly={viewOnly}
                    continueDisabled={continueDisabled}
                    projectChanged={projectChanged}
                />
            </div>
            <div className="column is-12">
                <SaveAndContinueButton
                    aliasStateData={aliasStateData}
                    duplicateAliasError={duplicateAliasError}
                    continueDisabled={continueDisabled}
                    setProjectChanged={setProjectChanged}
                />
            </div>
        </div>
    )
}

export default ProjectSearchForm
