import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Navbar from './components/features/navigation/Navbar'
import Map from './components/features/explorer/map/Map'
import Explorer from './components/features/explorer/Explorer'
import WellExplorer from './components/features/explorer/wells/Explorer'
import SampleExplorer from './components/features/explorer/samples/Explorer'
import { ToastContainer } from 'react-toastify'

import Header from './components/elem/Header'
import ParameterContextProvider from './components/wrappers/ParameterContext'
import urls from './utils/constants/urls'
import AppStateContextProvider from './components/features/explorer/AppStateContext'
import UserContextProvider from './components/wrappers/UserContext'
import RestrictedAccess from './components/features/user/RestrictedAccess'
import APIRequestContextProvider from './components/wrappers/APIRequestContext'
import Submissions from './components/features/submissions/Submissions'
import SubmissionTypeSelectionDialogue from './components/features/submissions/SubmissionTypeSelectionDialogue'
import NewUploadDialogue from './components/features/submissions/NewUploadDialogue'
import Submission from './components/features/submissions/form/Submission'
import SubmissionDataContext from './components/features/submissions/DataContext'
import ExistingSubmissionDataContextProvider from './components/features/submissions/form/ExistingSubmissionDataContext'
import TableContextProvider from './components/features/submissions/table/TableContext'
import EDDDataContextProvider from './components/features/submissions/edd/EDDDataContext'
import EDD from './components/features/submissions/edd/EDD'
import ViewEDD from './components/features/submissions/edd/ViewEDD'
import ReviewEDD from './components/features/submissions/edd/ReviewEDD'
import ExistingEDDDataContextProvider from './components/features/submissions/edd/ExistingEDDDataContext'
import CallbackComponent from './components/features/user/CallbackComponent'
import PressureVolumeExplorer from './components/features/explorer/pressureVolume/Explorer'

class App extends Component {
    render() {
        return (
            <>
                <Header />
                <Router>
                    <UserContextProvider>
                        <APIRequestContextProvider>
                            <ParameterContextProvider>
                                <Navbar />
                                <div className="pageContent">
                                        <AppStateContextProvider>
                                            <Switch>
                                                <Route exact path={urls.well}>
                                                    <WellExplorer width={'full'} />
                                                </Route>
                                                <Route exact path={urls.sample}>
                                                    <SampleExplorer width={'full'} />
                                                </Route>
                                                <Route exact path={urls.pressure}>
                                                    <PressureVolumeExplorer width={'full'} />
                                                </Route>
                                                <Route exact path={urls.map}>
                                                    <Map width={'full'} />
                                                </Route>
                                                <Route path={urls.edd}>
                                                    <SubmissionDataContext>
                                                        <EDDDataContextProvider>
                                                            <Switch>
                                                                <Route path={urls.viewEDD} exact>
                                                                    <ExistingEDDDataContextProvider>
                                                                        <ViewEDD />
                                                                    </ExistingEDDDataContextProvider>
                                                                </Route>
                                                                <Route path={urls.reviewEDD} exact>
                                                                    <ExistingEDDDataContextProvider>
                                                                        <ReviewEDD />
                                                                    </ExistingEDDDataContextProvider>
                                                                </Route>
                                                                <Route path={urls.edd} exact>
                                                                    <EDD />
                                                                </Route>
                                                            </Switch>
                                                        </EDDDataContextProvider>
                                                    </SubmissionDataContext>
                                                </Route>
                                                <Route path={urls.manageSampleResults}>
                                                    <SubmissionDataContext>
                                                        <Switch>
                                                            <Route exact path={urls.uploadSelectionDialogue}>
                                                                <RestrictedAccess allowedRoles={["Submitter", "Form_Reviewer"]}>
                                                                    <SubmissionTypeSelectionDialogue />
                                                                </RestrictedAccess>
                                                            </Route>
                                                            <Route exact path={urls.uploadDialogue}>
                                                                <RestrictedAccess allowedRoles={["Submitter", "Form_Reviewer"]}>
                                                                    <NewUploadDialogue />
                                                                </RestrictedAccess>
                                                            </Route>
                                                            <Route exact path={urls.editForm}>
                                                                <RestrictedAccess allowedRoles={["Submitter", "Form_Reviewer"]}>
                                                                    <ExistingSubmissionDataContextProvider>
                                                                        <Submission />
                                                                    </ExistingSubmissionDataContextProvider>
                                                                </RestrictedAccess>
                                                            </Route>
                                                            <Route exact path={urls.reviewForm}>
                                                                <RestrictedAccess allowedRoles={["Form_Reviewer"]}>
                                                                    <ExistingSubmissionDataContextProvider>
                                                                        <Submission />
                                                                    </ExistingSubmissionDataContextProvider>
                                                                </RestrictedAccess>
                                                            </Route>
                                                            <Route exact path={urls.form}>
                                                                <RestrictedAccess allowedRoles={["Submitter", "Form_Reviewer"]}>
                                                                    <Submission />
                                                                </RestrictedAccess>
                                                            </Route>
                                                            <Route exact path={urls.viewForm}>
                                                                <RestrictedAccess allowedRoles={["Submitter", "Form_Reviewer"]}>
                                                                    <ExistingSubmissionDataContextProvider>
                                                                        <Submission />
                                                                    </ExistingSubmissionDataContextProvider>
                                                                </RestrictedAccess>
                                                            </Route>
                                                            <Route exact path={urls.manageSampleResults}>
                                                                <RestrictedAccess allowedRoles={["Submitter", "Form_Reviewer"]}>
                                                                    <TableContextProvider>
                                                                        <Submissions />
                                                                    </TableContextProvider>
                                                                </RestrictedAccess>
                                                            </Route>
                                                        </Switch>
                                                    </SubmissionDataContext>
                                                </Route>
                                                <Route exact path={urls.userCallback}>
                                                    <CallbackComponent />
                                                </Route>
                                                <Route exact path={urls.home}>
                                                    <Explorer />
                                                </Route>
                                            </Switch>
                                        </AppStateContextProvider>
                                </div>
                            </ParameterContextProvider>
                        </APIRequestContextProvider>
                    </UserContextProvider>
                </Router>
                <ToastContainer />
            </>
        )
    }
}

export default App
