import React, { useEffect, useRef, useContext, useState, useCallback } from 'react'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import Vector from 'ol/source/Vector'
import VectorImage from 'ol/layer/VectorImage'
import GeoJSON from 'ol/format/GeoJSON'
import { transform } from 'ol/proj'
import { unByKey } from 'ol/Observable'
import { DataContext } from './DataContext'
import { MapControlContext } from './MapControlContext'
import Spinner from '../../../elem/Spinner'
import styleFunction, { getBaseDataLayer, getLayerLegendObject } from './mapStyles'
import { zoomControls, displayPopup } from './mapConfig'
import MapTooltip from './tooltip/MapTooltip'
import getLayer from './layers/getLayer'
import withConfig from '../../../wrappers/withConfig'
import { AppStateContext } from '../AppStateContext'
import { facilityLegendStyles, pressureLegends } from './mapStyles'

const ExplorerMap = ({ config }) => {
    const {
        data,
        loading,
        filteredData,
        fetchTooltipData,
        layerData,
        isCollapsed
    } = useContext(DataContext)
    const {
        controls,
        activeControl,
        displayTooltip,
        layerState,
        setLayerState,
    } = useContext(MapControlContext)
    const {
        setMapState,
        mapState: { selectedFeatures },
    } = useContext(AppStateContext)   

    const { API_URL, MAP, FACILITY_TITLE } = config
    const mapRef = useRef(null)
    const [map, setMap] = useState(null)
    const [themedDataLayer, setThemedDataLayer] = useState([])
    const [unthemedDataLayer, setUnthemedDataLayer] = useState(null)
    const [source, setSource] = useState(null)
    const [eventKeys, setEventKeys] = useState({
        boundingBox: [],
        polygon: [],
        select: [],
    })
    const [popupKey, setPopupKey] = useState(null)
    const [tooltipState, setTooltipState] = useState(null)

    // on load, set up the map
    useEffect(() => {
        const map = new Map({
            target: mapRef.current,
            controls: zoomControls(MAP.ZOOM_TO_EXTENT),
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            view: new View({
                projection: 'EPSG:3857',
                center: transform(
                    MAP.CENTER_LAT_LNG,
                    'EPSG:4326',
                    'EPSG:3857'
                ),
                zoom: MAP.INITIAL_ZOOM_LEVEL,
            }),
        })
        setMap(map)
        setMapState(prevMapState => ({ ...prevMapState, map }))
    }, [null])

// scale size of points based on zoom level
    const getStyleFunction = (theme, feature) => {
        let zoom = map.getView().getZoom() - 6
        if (zoom > 7) zoom = 7
        if (zoom < 0) zoom = 0
        return styleFunction(theme, feature, zoom)
    }

    const addNewCustomLayerToMap = useCallback((layerObject, baseAddLayer) => {
        setLayerState(prevLayerState => ({ ...prevLayerState, ...layerObject }))
        const layerName = Object.keys(layerObject).map(x => layerObject[x].LayerName)[0]
        if (layerName) {
            map.removeLayer(themedDataLayer[layerName])
            setThemedDataLayer(prev => ({...prev, [layerName]: baseAddLayer}))
            map.addLayer(baseAddLayer)
        }
    }, [map, setThemedDataLayer, themedDataLayer])

    // on data load, set the map vector source + layer
    useEffect(() => {
        if (data.type === 'FeatureCollection') {
            // get features from geojson data
            let features = new GeoJSON()
                .readFeatures(data)
                .filter(x => x.getGeometry().getCoordinates().length === 2)
                .map(x => {
                    x.getGeometry().transform('EPSG:4326', 'EPSG:3857')
                    x.set('selected', 0)
                    x.set('displayed', 1)
                    return x
                })
                .filter(x => {
                    const coords = x.getGeometry().getCoordinates()
                    return !((isNaN(coords[0]) || isNaN(coords[1])))
                })
            const s = new Vector({
                features,
            })
            setSource(s)
            setMapState(prevMapState => ({
                ...prevMapState,
                allFeatures: features,
            }))

            // add the basic facility layer
            const dataLayerUnthemed = new VectorImage({
                source: s,
                visible: true,
                style: getStyleFunction.bind(this, false),
                minResolution: 0,
                zIndex: 100,
            })
            dataLayerUnthemed.set('name', 'Point')
            setLayerState(prevLayerState => ({
                ...prevLayerState,
                Point: {
                    display: true,
                    expanded: false,
                    unique: true,
                    LayerName: 'Point',
                    layerGroupName: `${FACILITY_TITLE}s`,
                    LayerType: 'FEATURE',
                    loaded: true,
                    shape: 'circle',
                    StyleObj: JSON.stringify({
                        fillColor: 'grey',
                        strokeColor: 'grey',
                        strokeWidth: 2,
                        circle: true,
                    }),
                },
            }))
            map.removeLayer(unthemedDataLayer)
            setUnthemedDataLayer(dataLayerUnthemed)
            map.addLayer(dataLayerUnthemed)

            const dataLayerName = 'Sampling Point Type / Agency'
            const samplingPointTypeDataLayerObject = getLayerLegendObject(dataLayerName, facilityLegendStyles, `${FACILITY_TITLE}s`, 'circle')
            const dataLayer = getBaseDataLayer(dataLayerName, getStyleFunction.bind(this, 'samplingPointType'), s)
            addNewCustomLayerToMap(samplingPointTypeDataLayerObject, dataLayer)

            const dataLayerPressureName = 'Sampling Point Pressure / Flow Data'
            const pressureDataLayerObject = getLayerLegendObject(dataLayerPressureName, pressureLegends, `${FACILITY_TITLE}s`, 'square')
            const dataLayerPressure = getBaseDataLayer(dataLayerPressureName, getStyleFunction.bind(this, 'samplingPointPressure'), s)
            addNewCustomLayerToMap(pressureDataLayerObject, dataLayerPressure)
            
            // // add the themed facility layer
            // const dataLayer = new VectorImage({
            //     source: s,
            //     visible: true,
            //     style: getStyleFunction.bind(this, 'samplingPointType'),
            //     minResolution: 0,
            //     zIndex: 100,
            // })
            // dataLayer.set('name', dataLayerName)
            // const samplingPointTypeDataLayerObject = {
            //     [dataLayerName]: {
            //         display: false,
            //         expanded: false,
            //         unique: true,
            //         LayerName: dataLayerName,
            //         layerGroupName: `${FACILITY_TITLE}s`,
            //         loaded: true,
            //         shape: 'circle',
            //         StyleArray: JSON.stringify(facilityLegendStyles),
            //         LayerType: 'THEME'
            //     },
            // }
            // addNewCustomLayerToMap(samplingPointTypeDataLayerObject, dataLayer)


            // const dataLayerPressure = new VectorImage({
            //     source: s,
            //     visible: true,
            //     style: getStyleFunction.bind(this, 'samplingPointPressure'),
            //     minResolution: 0,
            //     zIndex: 100,
            // })
            // dataLayerPressure.set('name', dataLayerPressureName)
            // var pressureDataLayerObject = {
            //     [dataLayerPressureName]: {
            //         display: false,
            //         expanded: false,
            //         unique: true,
            //         LayerName: dataLayerPressureName,
            //         layerGroupName: `${FACILITY_TITLE}s`,
            //         loaded: true,
            //         shape: 'circle',
            //         StyleArray: JSON.stringify(pressureLegends),
            //         LayerType: 'THEME'
            //     },
            // }
            // addNewCustomLayerToMap(pressureDataLayerObject, dataLayerPressure)
        }
    }, [data])

    // on layer info load, add layers to the map
    useEffect(() => {
        if (map && layerData) {
            async function fetchLayers() {
                Object.keys(layerData).forEach(key => {
                    const layers = layerData[key]
                    layers.map(async layer => {
                        const newLayer = await getLayer(layer, API_URL)
                        if (newLayer) {
                            const layerName = layer.LayerName
                            newLayer.set('name', layerName)
                            map.addLayer(newLayer)
                            setLayerState(prevLayerState => ({
                                ...prevLayerState,
                                [layerName]: {
                                    ...prevLayerState[layerName],
                                    loaded: true,
                                },
                            }))
                        }
                    })
                })
            }
            fetchLayers()
        }
    }, [layerData, map, API_URL])

    // when layer state changes, update layer visibilites
    useEffect(() => {
        if (map && layerData && Object.keys(layerState).length) {
            map.getLayers().forEach(layer => {
                const layerName = layer.get('name')
                if (layerName && layerState[layerName]) {
                    layer.setVisible(layerState[layerName].display)
                }
            })
        }
    }, [map, layerData, layerState])

    // update the layer when filteredData changes
    useEffect(() => {
        if (data.type === 'FeatureCollection') {
            const newSelectedFeatures = []
            source.getFeatures().map(x => {
                const facilityID = x.get('FacilityID')
                // if the filter hides the feature, set the displayed
                // property to 0
                const isDisplayed =
                    filteredData.indexOf(facilityID) > -1
                x.set('displayed', isDisplayed ? 1 : 0)
                // if the feature is still displayed, carry over the selected
                // property from selectedFeatures.

                if (isDisplayed) {
                    const selectedFeature =
                    selectedFeatures.find(f => f.get('FacilityID') === facilityID)

                    if (selectedFeature) {
                        x.set('selected', 1)
                        newSelectedFeatures.push(x)
                    } else {
                        x.set('selected', 0)
                    }
                } else {
                    x.set('selected', 0)
                }
                return x
            })
            
            // update the selectedFeatures list to include
            // carry over features
            setSelectedFeatures(newSelectedFeatures)

            // console.log(
            //     `Total points: ${data.features.length}, Filtered Points: ${
            //         filteredData.length
            //     }, Features: ${source.getFeatures().length}`
            // )
        }
    }, [filteredData])

    // update size on load finished
    useEffect(() => {
        if (map) {
            map.updateSize()
        }
    }, [loading])

    // add / remove interactions on control change
    useEffect(() => {
        if (map) {
            // remove all other controls
            Object.keys(controls).map(controlName => {
                const control = controls[controlName]
                map.removeInteraction(control.control)
                if (control.layer) {
                    control.layer.setVisible(false)
                }
                return null
            })

            // set control
            if (activeControl) {
                const control = controls[activeControl]
                map.addInteraction(control.control)
                if (control.layer) {
                    control.layer.setVisible(true)
                }
            }
        }
    }, [activeControl])

    // tie control eventListeners to source when it changes
    useEffect(() => {
        // remove existing keys
        Object.keys(eventKeys).map(controlName => {
            const keys = eventKeys[controlName]
            keys.map(key => {
                unByKey(key)
                return null
            })
            return null
        })

        // loop over controls and set the on events for each
        const newEventKeys = Object.keys(controls).reduce(
            (acc, controlName) => {
                const control = controls[controlName]
                if (control.on) {
                    const keys = control.on(source, setSelectedFeatures)
                    return {
                        ...acc,
                        [controlName]: keys,
                    }
                } else {
                    return acc
                }
            },
            {}
        )
        setEventKeys(newEventKeys)
    }, [source])

    // watch the selected features map state and update selectedFeatures on
    // update
    useEffect(() => {
        if (map) {
            updateSelectedFeatures(selectedFeatures)
        }
    }, [selectedFeatures])

    // remove popover when displayTooltip is
    // set to false
    useEffect(() => {
        if (map) {
            unByKey(popupKey)
            if (displayTooltip || activeControl === 'select') {
                const key = map.on('pointermove', e =>
                    displayPopup(e, map, setTooltipState, fetchTooltipData)
                )
                setPopupKey(key)
            }
        }
    }, [displayTooltip, map, activeControl])

    const setSelectedFeatures = features => {
        // update the features
        setMapState(currentMapState => ({
            ...currentMapState,
            selectedFeatures: features,
        }))
    }

    const updateSelectedFeatures = selectedFeatures => {
        // clear selected features
        const features = source.getFeatures()
        features.map(feature => feature.set('selected', 0))

        // loop through the features provided to the function
        // and set 'selected' property to 1
        // features.map(feature => selectedFeatures.includes(feature.get('FacilityID')) ? feature.set('selected', 1) : null)
        selectedFeatures.map(feature => feature.set('selected', 1))
    }
  
    return (
        <>
            <div className="wellWrapper">
                {loading ? <Spinner /> : null}
                <div
                    id="map"
                    ref={mapRef}
                    className={`wellMapContainer ${isCollapsed ? 'is-collapsed': ''} ${loading ? 'is-hidden' : ''}`}
                ></div>
                <MapTooltip {...tooltipState} />
            </div>
        </>
    )
}

export default withConfig(ExplorerMap)
