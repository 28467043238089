import React, { useContext } from 'react'
import { DataContext } from '../DataContext'
import AggregateResetSelector from '../../../../elem/chart/AggregateResetSelector'

const ResetMeasureDropDown = () => {
    const { resetZoom, resetMeasures, measureResetExpanded, toggleMeasureResetExpanded } = useContext(DataContext)

    const onResetMeasures = () => {
        resetMeasures()
        toggleMeasureResetExpanded(false)
    }

    const onResetZoom = () => {
        resetZoom()
        toggleMeasureResetExpanded(false)
    }

    return (
        <AggregateResetSelector
            resetExpanded={measureResetExpanded}
            resetMeasuresFunction={onResetMeasures}
            resetZoomFunction={onResetZoom}
        />
    )
}

export default ResetMeasureDropDown