import React, { useContext, useMemo, useCallback, useState } from 'react'
import parseColumns from '../../../../../utils/table/parseColumns'
import NoDataComponent from '../../../../elem/table/ssr/NoDataComponent'
import withConfig from '../../../../wrappers/withConfig'

import { FacilityAssignmentMITContext } from './DataContext'
import TableContent from '../../../../elem/table/ssr/TableContent'
import clickSelection from '../../../../../utils/table/clickSelection'

export default withConfig(({config}) => {
    const { 
        assignedFacilityData, 
        params,
        setParams,
        loadingPossibleFacilities,
        possibleFacilityData,
        possibleFacilityMetadata,
        projectFacilityListConfig,
        highlightedPossibleFacilities,
        setHighlightedPossibleFacilities, 
    } = useContext(FacilityAssignmentMITContext)
    const { pageCount, count, pageSize } = possibleFacilityMetadata
    const { LINK_COLUMN, ID_COLUMN, FACILITY_TITLE} = config

    const adjustedPageCount = (count / pageSize) % 1 === 0 ? pageCount - 1 : pageCount
    const columns = useMemo(() => parseColumns(possibleFacilityData, projectFacilityListConfig, LINK_COLUMN, ID_COLUMN))

    const data = useMemo(() => {        
        const assignedFacilityIds = assignedFacilityData.map(x => x[ID_COLUMN])
        return possibleFacilityData.filter(x => !assignedFacilityIds.includes(x[ID_COLUMN]))
    }, [possibleFacilityData, assignedFacilityData])

    const selectedItems = useMemo(() => highlightedPossibleFacilities.map(x => x[ID_COLUMN]), [highlightedPossibleFacilities, ID_COLUMN])

    const [lastSelection, setLastSelection] = useState(null)

    const onClick = useCallback((row, isHighlighted, e, page) => {
        clickSelection(e, row, page, highlightedPossibleFacilities, data, lastSelection, ID_COLUMN, setHighlightedPossibleFacilities, setLastSelection)
    }, [highlightedPossibleFacilities, data, lastSelection])

    const updateParams = newParams => {
        setParams(newParams, 'possibleMITFacilities')
        setHighlightedPossibleFacilities([])
    }

    return (
        <div className="possibleFacilitiesPositioning">
            <div className="possibleFacilitiesWrapper">
                <div className="has-text-centered is-size-5 has-text-weight-semibold">
                    {`Available ${FACILITY_TITLE}s`}
                </div>
                {count === 0 ? (
                        <div className="column is-12 has-text-centered">
                            <NoDataComponent 
                                filterExpanded={false}
                                toggleFilter={() => {}}
                                subject='No results found. Please refine filter results'
                                message=''
                            />
                        </div>
                    ) : (
                        <TableContent
                            name="possibleMITFacilities"
                            {
                                ...{
                                    columns,
                                    loading: loadingPossibleFacilities,
                                    params,
                                    onClick,
                                    setParams: updateParams,
                                    resultsCount: count,
                                    pageCount: adjustedPageCount,
                                    data,
                                    filterExpanded: false,
                                    filterFields: projectFacilityListConfig,
                                    showAppliedFilterString: false,
                                    selectedItems,
                                    extraClass: 'facilityAssignmentTable',
                                    selectedItemColumn: ID_COLUMN,
                                    promotedRecords: true
                                }
                            }
                        />
                    )
                }
            </div>
        </div>
    )
})