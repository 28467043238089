import React, { useContext } from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { Controller } from 'react-hook-form'

import Tooltip from '../Tooltip'
import { ParameterContext } from '../../wrappers/ParameterContext'
import { PanelStyleContext } from '../panel/PanelStyleContext'

const mapValuesToOptions = (values, fieldAccessorMap) => {
    const options = values
        .filter(v => v[fieldAccessorMap.value] !== '')
        .map(v => {
            return {
                label: v[fieldAccessorMap.label],
                value: v[fieldAccessorMap.value],
            }
        })
    return options
}

const getInitialValue = (name, formName, params, options) => {
    const filterName = `${name}Value`
    const initialFilterValue = params[formName] && params[formName][filterName] ? params[formName][filterName] : ''
    const initialValue = options.find(x => x.value === initialFilterValue.toString() || x.value === initialFilterValue || x.value === parseInt(initialFilterValue))
    return initialValue
}

const Checkbox = ({ name, fieldName, values, control, register, registerParams, helper, fieldAccessorMap, formWidth, formName }) => {
    const { params } = useContext(ParameterContext)
    const { tooltipClass } = useContext(PanelStyleContext)
    const options = mapValuesToOptions(values, fieldAccessorMap)
    const initialValue = getInitialValue(name, formName, params, options)
    return (
        <div className={`column ${formWidth === 'full' ? 'is-one-third' : 'is-full'} no-vertical-padding formInputWrapper`}>
            <div className="formInputLayout">
                <div className="field">
                    <div className="field-label is-small">
                        <label className="label checkboxLabel">
                            <Controller 
                                as={
                                    <input
                                        type='checkbox'
                                        className="formCheckbox"
                                        />
                                    }
                                defaultChecked={initialValue ? true : false}
                                onChange={([e]) => {
                                    return e.currentTarget.checked
                                }}
                                name={`${name}Checkbox`} 
                                control={control}
                                valueName='checked'
                            />
                            {fieldName}
                            {helper && (
                                <div 
                                    data-tip={`${helper}`} 
                                    data-for={name}
                                    className="formHelper inline"
                                >
                                    <span
                                        
                                        className="is-static is-small"
                                    >
                                        <FaInfoCircle />
                                        <Tooltip id={name} className={`${tooltipClass} tooltip-text-align `} />
                                    </span>
                                </div>
                            )}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Checkbox
