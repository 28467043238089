import React, { useContext } from 'react'

import Filter from './Filter'
import List from './List'
import DataContextProvider from './DataContext'
import Panel from '../../../elem/panel/Panel'
import { samplePanel as panelClassConfig } from '../../../../utils/styles/panelStyles'
import { AppStateContext } from '../AppStateContext'
import SampleHeader from './SampleHeader'

const Explorer = ({ width }) => {
    const {
        filterState,
        updateFilterState,
        syncFilterState,
        promotedRecords,
        togglePromotedRecords,            
    } = useContext(AppStateContext)

    const [isCollapsed, setCollapsed] = React.useState(false)
    const filterExpanded = filterState['sample']
    const toggleFilter = updateFilterState.bind(this, 'sample')

    return (
        <Panel panelClassConfig={panelClassConfig(width)}>
            <div className="explorerWrapper">
                <DataContextProvider>
                    <SampleHeader
                        filterExpanded={filterExpanded}
                        toggleFilter={toggleFilter}
                        promotedRecords={promotedRecords['Sample']}
                        togglePromotedRecords={togglePromotedRecords}
                        setCollapsed={setCollapsed}
                        isCollapsed={isCollapsed}
                    />
                    {
                        !isCollapsed && 
                    (<Filter
                        width={width}
                        filterExpanded={filterExpanded}
                        syncFilterState={syncFilterState}
                    />)
                    }
                      {
                        !isCollapsed && 
                    (
                    <List
                        promotedRecords={promotedRecords}
                        filterExpanded={filterExpanded}
                        toggleFilter={toggleFilter}
                    />
                    )}
                </DataContextProvider>
            </div>
        </Panel>
    )
}

export default Explorer
