import React from 'react'
import dayjs from 'dayjs'

export default ({uploadData}) => {
    if (uploadData && uploadData.UploadStatus === 3) {
        const isEDD = uploadData.IsEDD
        return (
            <div className="column is-12">
                <div className="notification is-danger">
                    <p>
                        This {isEDD ? `EDD` : `form`} was denied on {dayjs(uploadData.ModifyDate).format('MM/DD/YYYY').toString()} with the following comments:
                    </p> 
                    <p>
                        {uploadData.Comment}
                    </p>
                    <p>
                        {isEDD ? <span>Please <b>submit a new EDD that addresses the attached comments</b>.</span> : <span>Please submit <b>a new form that addresses the attached comments.</b></span>}
                    </p>
                </div>
            </div>
        )
    } else if (uploadData && uploadData.UploadStatus === 2) {
        const isEDD = uploadData.IsEDD
        return (
            <div className="column is-12">
                <div className="notification is-success">
                    <p>
                        This {isEDD ? `EDD` : `form`} was approved on {dayjs(uploadData.ModifyDate).format('MM/DD/YYYY').toString()} with the following comments:
                    </p> 
                    <p>
                        {uploadData.Comment ? uploadData.Comment : 'No comment provided'}
                    </p>
                    <p>
                        The data associated with this submission is now live.
                    </p>
                </div>
            </div>
        )
    } else {
        return null
    }
}