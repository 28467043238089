import React from 'react'
import ResetZoomButton from './ResetZoomButton'

const AnalyteResetSelector = ({
    resetExpanded,
    resetZoomFunction,
    resetAnalytesFunction,
}) => {
    if (!resetExpanded) {
        return null
    }
    return (
        <div className="timeWindowDropdownWrapper">
            <ResetZoomButton resetFunction={resetZoomFunction} isInDropdown={true}/>
            <div
                className="panelButton timeWindowOptionWrapper"
                onClick={() => resetAnalytesFunction()}
            >
                <span className="timeWindowOptionText">Reset Analytes</span>
            </div>
        </div>        
    )
}

export default AnalyteResetSelector