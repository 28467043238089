import React, { useContext } from 'react'
import { AppStateContext } from '../AppStateContext'
import { FaTimes } from 'react-icons/fa'

import PanelHeader from '../../../elem/panel/PanelHeader'

export default () => {
    const { updateDetailState } = useContext(AppStateContext)

    return (
        <PanelHeader extraClass="wellDetailPanelHeader">
            <div className="panelHeaderText">Details</div>
            <div className="panelButtons">
                <div
                    className="panelButton"
                    onClick={e => {
                        e.preventDefault()
                        updateDetailState('well', { visible: false })
                    }}
                >
                    <FaTimes />
                </div>
            </div>
        </PanelHeader>
    )
}
