import React, {
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react'
import dayjs from 'dayjs'
import {
    ComposedChart,
    Line,
    YAxis,
    XAxis,
    Tooltip,
    Label,
    CartesianGrid,
    ResponsiveContainer,
    ReferenceArea,
    Legend,
} from 'recharts'

import { DataContext } from '../DataContext'
import Message from '../../../../elem/chart/Message'
import ChartTooltip from '../../../../elem/chart/Tooltip'
import { dateToString } from '../../../../../utils/dateUtils'
import palette from '../../../../../utils/chart/palette'
import { getAxisYDomain } from '../../../../../utils/chart/slice'
import { roundToFourDigits } from '../../../../../utils/chart/values'

const CustomLegend = ({ legendPayload }) => {
    return (
        <ul className="recharts-default-legend" style={{ textAlign: 'center' }}>
            {legendPayload.map((item, index) => (
                <li
                    key={`line-legend-${index}`}
                    className={`recharts-legend-item legend-item-${index}`}
                    style={{ display: 'inline-block', marginRight: '10px' }}
                >
                    <svg
                        className="recharts-surface"
                        width="14"
                        height="14"
                        viewBox="0 0 32 32"
                        version="1.1"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginRight: '4px',
                        }}
                    >
                        <path
                            stroke="none"
                            fill={item.color}
                            d="M0,4h32v24h-32z"
                            className="recharts-legend-icon"
                        ></path>
                    </svg>
                    <span className="recharts-legend-item-text">
                        {item.value}
                    </span>
                </li>
            ))}
        </ul>
    )
}

const getUnitFromData = (groupName, data) => {
    const exampleRow = data.find(x => x.Analyte === groupName)
    if (exampleRow) {
        return exampleRow.Units
    } else {
        return '(units unknown)'
    }
}

const DetailAnalyteChart = () => {
    const {
        analyteChartData,
        selectedAnalytes,
        isLoading,
        selectedUnits,
        zoomTrigger,
        visibleChartTab,
    } = useContext(DataContext)
    const [data, setData] = useState([])

    const [left, setLeft] = useState('dataMin')
    const [right, setRight] = useState('dataMax')
    const [refAreaLeft, setRefAreaLeft] = useState('')
    const [refAreaRight, setRefAreaRight] = useState('')
    const [top, setTop] = useState('dataMax')
    const [bottom, setBottom] = useState('dataMin')
    useEffect(() => {
        zoomOut()
    }, [zoomTrigger])

    useEffect(() => {
        if (analyteChartData) {
            const d = analyteChartData
                .filter(
                    d => !!selectedAnalytes.find(x => x.ParamID === d.ParamID)
                )
                .map(d => ({
                    ...d,
                    SampleDate: dayjs(d.SampleDate)
                        .toDate()
                        .getTime(),
                })) // convert sample date to unix time for x-axis range
            setData(d)
        }
    }, [analyteChartData, selectedAnalytes])

    // generate a line component for each
    // individual line

    const groupKey = 'Analyte'

    // get the set of all unique values in the column specified by groupKey
    const uniqueGroups = useMemo(
        () =>
            data &&
            data.length &&
            [...new Set(data.map(x => x[groupKey]))].sort(),
        [data]
    )

    // aggregate line chart data using unique dates. eg
    // [{SampleDate: date1, Analyte: "one" , Concentration: 100}, {SampleDate: date1, Analyte: "two", Concentration: 200}]
    // turns into [{Date: date1, "1": 100, "2": 200}]
    const chartData = useMemo(
        () =>
            data &&
            data.length &&
            [...new Set(data.map(d => d.SampleDate))].map(date => {
                const dateEntry = { SampleDate: date }
                return data
                    .filter(x => x.SampleDate === date) // get entries associated w/ the current date
                    .reduce((acc, curr) => {
                        const groupName = curr[groupKey]
                        const value = curr['Concentration']
                        return {
                            ...acc,
                            [groupName]: value,
                        }
                    }, dateEntry)
            }),
        [data]
    )
    
    const zoom = useCallback(() => {
        let RAL = refAreaLeft
        let RAR = refAreaRight
        if (RAL === RAR || RAR === '') {
            RAL = ''
            RAL = ''
            return
        }

        // xAxis domain
        if (RAL > RAR) {
            const temp = RAL
            RAL = RAR
            RAR = temp
        }

        // yAxis domain
        const leftAxisDomain = getAxisYDomain(
            RAL,
            RAR,
            x => !x.includes('SampleDate'),
            0,
            chartData,
            'SampleDate'
        )
        setBottom(leftAxisDomain[0])
        setTop(leftAxisDomain[1])
        setLeft(RAL)
        setRight(RAR)
        setRefAreaRight('')
        setRefAreaLeft('')
    }, [refAreaLeft, refAreaRight, chartData])

    const zoomOut = useCallback(() => {
        setRefAreaLeft('')
        setRefAreaRight('')
        setLeft('dataMin')
        setRight('dataMax')
        setTop('dataMax')
        setBottom('dataMin')
    }, [chartData])

    const Lines = useMemo(
        () =>
            uniqueGroups &&
            uniqueGroups.map((groupName, idx) => (
                <Line
                    type="monotone"
                    dot={{ r: 1 }}
                    key={`bar-${idx}`}
                    dataKey={`${groupName}`}
                    stackId={'shared'}
                    stroke={palette[idx % palette.length]}
                    unit={getUnitFromData(groupName, data)}
                    strokeWidth={1}
                    activeDot={{ r: 5 }}
                    animationDuration={200}
                />
            )),
        [uniqueGroups, data]
    )

    const legendData = useMemo(
        () =>
            uniqueGroups &&
            uniqueGroups.map((groupName, idx) => ({
                color: palette[idx % palette.length],
                type: 'line',
                dataKey: groupName,
                value: groupName,
            })),
        [uniqueGroups]
    )

    // if loading || no data, return nothing
    if (isLoading || visibleChartTab !== 'sampleResult') {
        return null
    }
    if (!data.length) {
        return <Message text={`No Chart Data Available`} />
    }

    // // construct chart title
    // const chartTitle = `RBDMS ID # ${RBDMSID}`

    // construct chart
    const yAxisLabel = `Concentration (${
        selectedUnits ? selectedUnits : 'units unknown'
    })`
    const xAxisLabel = 'Sample Date'

    return (
        <div className="chart">
            {/* <div className="chartTitle">{chartTitle}</div> */}
            <div className="chartWrapper">
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        data={chartData}
                        margin={{
                            top: 10,
                            right: 50,
                            left: 12,
                            bottom: 15,
                        }}
                        onMouseDown={e => e && e.activeLabel && setRefAreaLeft(e.activeLabel)}
                        onMouseMove={e => e && e.activeLabel && refAreaLeft && setRefAreaRight(e.activeLabel)}
                        onMouseUp={() => zoom()}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            allowDataOverflow
                            type="number"
                            dataKey="SampleDate"
                            domain={[left, right]}
                            tickFormatter={unixTime => dateToString(unixTime)}
                        >
                            <Label
                                value={xAxisLabel}
                                offset={-10}
                                position="insideBottom"
                                className="nitrateChartXAxisLabel"
                            />
                        </XAxis>
                        <YAxis
                            allowDataOverflow
                            type="number"
                            tickFormatter={v => roundToFourDigits(v)}
                            domain={[bottom, top]}
                        >
                            <Label
                                value={yAxisLabel}
                                angle={-90}
                                offset={20}
                                position="insideLeft"
                                className="detailChartYAxisLabel"
                            />
                        </YAxis>
                        <Tooltip content={<ChartTooltip stagger={10}/>} />
                        <Legend
                            content={
                                <CustomLegend legendPayload={legendData} />
                            }
                            verticalAlign="top"
                            wrapperStyle={{
                                top: 1,
                                left: 0,
                                alignItems: 'center',
                                width: '100%',
                            }}
                        />
                        {Lines}
                        {refAreaLeft && refAreaRight ? (
                            <ReferenceArea
                                x1={refAreaLeft}
                                x2={refAreaRight}
                                strokeOpacity={0.3}
                            />
                        ) : null}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default DetailAnalyteChart
export { CustomLegend }
