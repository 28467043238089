import React from 'react'

import DataContextProvider from './DataContext'
import Panel from '../../../../elem/panel/Panel'
import { pressureAggregatePanel as panelClassConfig } from '../../../../../utils/styles/panelStyles'
import AggregateHeader from './AggregateHeader'
import AggregateChart from './AggregateChart'
// import TimeWindowDropdown from './TimeWindowDropdown'
import ResetDropdown from './ResetDropDown'
import MeasureDropdown from './MeasureDropdown'
import GroupingDropDown from './GroupingDropDown'

const PressureAggregate = ({ width }) => {
    return (
        <Panel panelClassConfig={panelClassConfig(width)}>
            <div className="explorerWrapper">
                <DataContextProvider>
                    <AggregateHeader />
                    <ResetDropdown />
                    <MeasureDropdown />
                    <GroupingDropDown />
                    {/* <TimeWindowDropdown /> */}
                    <AggregateChart />
                </DataContextProvider>
            </div>
        </Panel>
    )
}

export default PressureAggregate