import AliasForm from './form/AliasForm'
import FacilityAssignment from './form/facilityAssignment/FacilityAssignment'
import MITFacilityAssignment from './form/facilityAssignmentMIT/FacilityAssignment'
import SearchForm from './form/SearchForm'
import ReviewSubmission from './form/ReviewSubmission'
import OperatorSearchForm from './form/operatorSearch/OperatorSearchForm'
import ProjectSearchForm from './form/projectSearch/ProjectSearch'

export default (formType, configuredNavGroups, isSearchTabVisible, isReview) => {

    const primaryAccessor = formType.PrimaryAccessor

    const aliasTab = {
        groupName: `${formType.Name} Alias Information`,
        requires: null,
        type: 'component',
        props: {
            formType
        },
        component: AliasForm,
        dataAccessor: `${primaryAccessor}Alias`
    }

    const searchTab = {
        groupName: `${formType.Name} Search`,
        requires: null,
        type: 'component',
        component: SearchForm,
        props: {
            formType
        },
        dataAccessor: primaryAccessor,
    }
    
    const samplingPointAssignmentTab = {
        groupName: `Sampling Point Assignments`,
        requires: 'project',
        type: 'component',
        component: FacilityAssignment,
        props: { formType },
        dataAccessor: 'projectFacility'
    }

    const mitFacilityAssignment = {
        groupName: `Sampling Point Assignments`,
        // requires: 'project',
        requires: null,
        type: 'component',
        component: MITFacilityAssignment,
        props: { formType },
        dataAccessor: 'mit'
    }

    const reviewTab = {
        groupName: 'Review',
        requires: null,
        type: 'component',
        component: ReviewSubmission,
        dataAccessor: primaryAccessor,
    }

    const operatorSearchTab = {
        groupName: `Operator Search`,
        requires: null,
        type: 'component',
        props: { formType },
        component: OperatorSearchForm,
        dataAccessor: 'operatorSearch'
    }

    const projectSearchTab = {
        groupName: 'Facility Search',
        requires: null,
        type: 'component',
        props: { formType },
        component: ProjectSearchForm,
        dataAccessor: primaryAccessor
    }

    let allNavGroups = configuredNavGroups
    switch (formType.APILink) {
        case 'facility':
            allNavGroups = [
                aliasTab,
                ...allNavGroups
            ]
            if(isSearchTabVisible) {
                allNavGroups = [searchTab, ...allNavGroups]
            }
            break;
        case 'project':
            allNavGroups = [
                searchTab,
                aliasTab,
                ...allNavGroups,
                samplingPointAssignmentTab
            ]
            break;
        case 'mit':
            allNavGroups = [
                mitFacilityAssignment,
                ...allNavGroups,
            ]
            if(isSearchTabVisible) {
                allNavGroups = [operatorSearchTab, ...allNavGroups]
            }
            break;
        case 'pressure':
            if (isSearchTabVisible) {
                allNavGroups = [
                    projectSearchTab,
                    ...allNavGroups
                ]
            }
            break;
        default:
            allNavGroups = [
                searchTab,
                aliasTab,
                ...allNavGroups
            ]
            break;
    }
    
    if (isReview) {
        allNavGroups = [ ...allNavGroups, reviewTab]
    }
    return allNavGroups
} 
