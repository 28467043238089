import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const timeWindowOptions = [
    {
        name: 'All Time',
        amount: null,
        unit: 'year',
    },
    {
        name: '20 Years',
        amount: 20,
        unit: 'year',
    }
]

const filterTimeData = (data, timeDiffObject, timeDataField = 'SampleDate') => {
    if (timeDiffObject.amount) {
        const edgeDate = dayjs().subtract(
            timeDiffObject.amount,
            timeDiffObject.unit
        )
        const newData = data.filter(d => {
            const date = d[timeDataField]
            return dayjs(date).diff(edgeDate) >= 0
        })
        return newData
    } else {
        return data
    }
}

const filterMultiSeriesTimeData = (data, timeDiffObject, timeDataField = 'SampleDate') => {
    return data.map(d => ({
        ...d,
        Data: filterTimeData(d.Data, timeDiffObject, timeDataField)
    }))
}

const filterMultiSeriesTimePeriod = (data, dateParams, timeDataField = 'SampleDate') => {
    return data.map(d => ({
        ...d,
        Data: getDataWithinTimePeriod(d.Data, dateParams, timeDataField)
    }))
}

const getDataWithinTimePeriod = (data, dateParams, timeDataField = 'SampleDate') => {
    return data.filter(x => {
        if (dateParams[0] && dateParams[1]) {
            return dayjs(x[timeDataField]).isBetween(dateParams[0], dateParams[1])
        } else if (dateParams[0]) {
            return dayjs(x[timeDataField]).isSameOrAfter(dateParams[0])
        } else if (dateParams[1]) {
            return dayjs(x[timeDataField]).isSameOrBefore(dateParams[1])
        } else {
            return false
        }
    })
}

const resolveOperatorCondition = (operator, compareValue, value1, value2 = null) => {
    switch (operator) {
        case `=`:
            return compareValue === value1
        case `>=`:
            return compareValue >= value1 
        case `>`:
            return compareValue > value1
        case `<=`:
            return compareValue <= value1
        case `<`:
            return compareValue < value1
        case `>=,<=`:
            return value1 && value2
                ? compareValue >= value1 && compareValue <= value2
                : false
        default:
            return false
    }
   
}

const getDataWithinTimePeriodWithOperator = (data, dateParams, timeDataField) => {
    return data.filter(x =>
        resolveOperatorCondition(
            dateParams.operator,
            dayjs(x[timeDataField]).unix(),
            dayjs(dateParams.startDate).unix(),
            dayjs(dateParams.endDate).unix(),
        )
    )
}

export { timeWindowOptions, filterTimeData, getDataWithinTimePeriod, filterMultiSeriesTimeData, filterMultiSeriesTimePeriod, getDataWithinTimePeriodWithOperator }
export default filterTimeData
