import React from 'react'

import { timeWindowOptions } from '../../../utils/chart/timeWindow'
// import { DataContext } from '../../features/explorer/wellDetail/DataContext'
// import { PanelStyleContext } from '../panel/PanelStyleContext'

export default ({ timeWindow, setTimeWindow, displayTimeWindowDropdown, toggleTimeWindowDropdown, colorClass }) => {
    if (!displayTimeWindowDropdown) {
        return null
    }
    
    return (
        <div className="timeWindowDropdownWrapper">
            {timeWindowOptions.map((option, idx) => {
                const active = timeWindow.name === option.name
                return (
                    <div 
                        key={`timeoption-${idx}`}
                        className="timeWindowOptionWrapper"
                        onClick={() => {
                            setTimeWindow(option)
                            toggleTimeWindowDropdown(false)
                        }}
                    >
                        <div className={`timeWindowOptionText ${active ? 'activeOption ' + colorClass : ''}`}>
                            {option.name}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}