import React, { useContext, useEffect, useState, } from 'react'
import SelectStyles from '../../../../elem/form/SelectStyles'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { APIRequestContext } from '../../../../wrappers/APIRequestContext'


export default ({ config, selectedOperator, setSelectedOperator }) => {
    
    const [operatorList, setOperatorList] = useState([])
    const { API_URL} = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    
    useEffect(() => {
        authenticatedFetch(
            `${API_URL}/upload/mit/getOperatorSearchList`
        ).then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(response => setOperatorList(response.operators))
        .catch(e =>
            toast({
                level: 'error',
                message:
                    `Submission Error: ` + e.message,
                alert: true,
            })
        )
    }, [])
    
    return (
        <div className="column m-0 is-6 field is-horizontal">
            <div className="field-label mt-2">
                <label className="label">Operator:</label>
            </div>
            <div className="field-body">
                <div className="field">
                    <div className="control">
                        <Select
                            className="select is-multiple is-fullwidth reactSelect"
                            classNamePrefix="reactSelect"
                            // inputRef={ref}
                            options={operatorList}
                            styles={SelectStyles}
                            menuPlacement="auto"
                            // defaultValue={[{ value: true, label: 'Yes' }]}
                            onChange={data => {
                                setSelectedOperator(data.value)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}


