import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
    useMemo,
} from 'react'
import { useForm } from 'react-hook-form'

import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
import withConfig from '../../../../wrappers/withConfig'
import Select from 'react-select'
import SelectStyles from '../../../../elem/form/SelectStyles'
import OperatorDropDown from './OperatorDropDown'
import { DataContext } from '../../DataContext'

const OperatorSearchForm = ({ formType, config }) => {
    const {
        activePanel,
        // submissionState,
        viewOnly,
        setFormMethods,
        setFormDirty,
        setSubmissionState,
    } = useContext(DataContext)
    const { authenticatedFetch } = useContext(APIRequestContext)

    const [selectedOperator, setSelectedOperator] = useState(null)
    const [regularReporting, setRegularReporting] = useState(null)

    const [loading, setLoading] = useState(false)
    const formMethods = useForm({ mode: 'onChange' })
    const { handleSubmit, formState } = formMethods
    const { dirty } = formState
    const { API_URL } = config
    const [error, setError] = useState(null)

    useEffect(() => {
        setFormMethods(formMethods)
    }, [])

    useEffect(() => {
        setFormDirty(dirty)
    }, [dirty])

    const addOperator = useCallback(() => {
        if (selectedOperator === '' || selectedOperator === null ) {
            setError('Please select a value for the Operator field.')
            return
        }
        if (regularReporting === '' || regularReporting === null) {
            setError(`Please select a value for the Regular Reporting? field.`)
            return
        }
        setLoading(true)
        authenticatedFetch(
            `${API_URL}/upload/mit/setOperatorData?agentId=${selectedOperator}&regularReporting=${regularReporting}`,
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(res => {
                setSubmissionState(prev => ({
                    ...prev,
                    FacilityAgentMIT: [],
                    Agent: {
                        ...res.agent,
                        MITRegularReporting: regularReporting
                    }   
                }))
                setError(null)
            })
            .catch(e => console.log(`Operator Search: Something went wrong. ${e}`))
            .finally(() => setLoading(false))
    }, [selectedOperator, regularReporting])


    const continueDisabled = useMemo(() => 
        (selectedOperator === null || selectedOperator === undefined || selectedOperator === "") 
        || (regularReporting === null || regularReporting === undefined)
    , [selectedOperator, regularReporting])

    return (
            <div
                className={`columns is-multiline is-centered ${
                    activePanel !== `Operator Search` ? 'is-hidden' : ''
                }`}
            >
                <div className="column is-12">
                    <form
                        className="form"
                        onSubmit={handleSubmit(addOperator)}
                    >
                        <div className="is-size-4 has-text-centered matchingFacilityText">
                            <span className="has-text-weight-semibold">
                                {`Operator Search`}
                            </span>
                        </div>
                        <div className='columns m-4'>
                            <OperatorDropDown 
                                config={config}
                                selectedOperator={selectedOperator}
                                setSelectedOperator={setSelectedOperator}
                            />
                            <div className="column is-6 field is-horizontal">
                                <div className="field-label mt-2">
                                    <label className="label">Regular Reporting?</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <Select
                                                className="select is-multiple is-fullwidth reactSelect"
                                                classNamePrefix="reactSelect"
                                                // inputRef={ref}
                                                options={[
                                                    { value: true, label: 'Yes' },
                                                    { value: false, label: 'No' },
                                                ]}
                                                styles={SelectStyles}
                                                menuPlacement="auto"
                                                // defaultValue={[{ value: true, label: 'Yes' }]}
                                                onChange={data => setRegularReporting(data.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!viewOnly && (
                            <div className="columns is-centered is-multiline">
                                <div className="column is-12 has-text-centered">
                                    {error ? (
                                        <div className="has-text-danger">
                                            {error}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="column is-4 buttonWrapper">
                                    <div className="saveButtonWrapper">
                                        <button
                                            className={`
                                                button is-link is-medium 
                                                ${ loading ? 'is-loading' : '' }
                                                ${ continueDisabled ? 'is-disabled' : ''}
                                            `}
                                            disabled={continueDisabled}
                                            type="submit"
                                        >
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        
    )
}

export default withConfig(OperatorSearchForm)