import React, { useContext, useState, useEffect, useRef } from 'react'

import { DataContext } from '../DataContext'
import { MapControlContext } from '../MapControlContext'
import TooltipLoading from './TooltipLoading'
import TooltipData from './TooltipData'

export default ({ left, top, right, bottom, facilityID }) => {
    const tooltipRef = useRef(null)
    const { tooltipData, tooltipFilterData, tooltipLoading } = useContext(
        DataContext
    )
    const { activeControl } = useContext(MapControlContext)

    // display logic 
    const [display, setDisplay] = useState('none')

    // when a new feature is highlighted,
    // set display block
    useEffect(() => {
        if ((facilityID && !activeControl) || (facilityID && activeControl === 'select')) {
            setDisplay('block')
        }
        else {
            setDisplay('none')
        }
    }, [facilityID])

    // when the tooltip loads,
    // setup mouseenter and mouseleave
    // functions to ensure mouseover
    // prevents tooltip from hiding
    useEffect(() => {
        if (tooltipRef.current) {
            tooltipRef.current.onmouseenter = () => setDisplay('block')
            tooltipRef.current.onmouseleave = () => setDisplay('none')
        }
    }, [tooltipRef.current])

    return (
        <div
            className="tooltipWrapper"
            style={{
                left,
                right,
                bottom,
                top,
                display,
            }}
            ref={tooltipRef}
        >
            {tooltipLoading ? (
                <TooltipLoading />
            ) : (
                <TooltipData
                    tooltipData={tooltipData}
                    tooltipFilterData={tooltipFilterData}
                />
            )}
        </div>
    )
}
