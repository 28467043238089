import React, { useContext, useEffect, useCallback, useMemo } from 'react'
import { useForm, FormContext } from 'react-hook-form'
import merge from 'lodash.merge'

import LoadingWrapper from '../../../wrappers/LoadingWrapper'
import { DataContext } from '../DataContext'
import Table from './TablePanel'
import Form from './FormPanel'
import SaveButton from './SaveButton'
import { convertFieldDuplicateString, getValuesFromData } from '../../../../utils/submissions/values'
import ResetButton from './ResetButton'
import { cloneDeep } from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { replaceBooleanWithInt } from '../../../../utils/submissions/numerical'
import { setReactiveValues } from '../../../../utils/form/reactiveValues'
import { getRequiredColumnValues as getForeignKeyRequiredColumnValues } from '../../../../utils/submissions/foreignKeys'
import moment from 'moment';

dayjs.extend(utc)

const getFormComponentType = type => {
    switch (type) {
        case 'Form':
            return Form
        case 'Table':
            return Table
        default:
            return null
    }
}

const getInputNameFromConfig = (config, fieldName) => {
    if (!config) {
        return fieldName
    }
    const name = config.ColumnName
    switch (config.ControlType) {
        case 'TextBox':
            return name
        case 'Select':
            return `${name}Select`
        case 'MultiSelect':
            return `${name}Select`
        case 'CheckBox':
            return `${name}CheckBox`
        case 'DateDayPicker':
            return `${name}DateSelect`
        case 'DatePicker':
            return `${name}DateSelect`
        case 'Creatable':
            return `${name}Select`
        default:
            return name
    }
}

const isNotNullOrUndefined = value => {
    return !(typeof value === 'undefined' || value === null)
}

const getSelectValue = (values, initialValue, defaultValue, config) => {
    const value = isNotNullOrUndefined(initialValue) ? initialValue : defaultValue ? defaultValue : ''
    const option = values ? values.find(
        x =>
            x.code === value.toString() ||
            x.code === value ||
            x.code === parseInt(value)
    ) : null
    if (option) {
        return { label: option.codedescription, value: option.code, active: option.active }
    }
    else {
        return value
    }
}

const getCreatableSelectValue = (values, initialValue, defaultValue) => {
    const value = isNotNullOrUndefined(initialValue) ? initialValue : defaultValue ? defaultValue : ''
    const option = values.find(
        x =>
            x.code === value.toString() ||
            x.code === value ||
            x.code === parseInt(value)
    )
    if (option) {
        return { label: option.codedescription, value: option.code, active: option.active }
    }
    else {
        if (value !== '') {
            return { label: value, value: value, active: true }
        } else {
            return value
        }
    }
}

const getCheckBoxValue = (initialValue, defaultValue) => {
    const value = isNotNullOrUndefined(initialValue) ? initialValue : defaultValue ? defaultValue : 0
    return value
}

const getValueFromUploadConfig = (initialValue, config) => {
    const value = replaceBooleanWithInt(initialValue)
    switch (config.ControlType) {
        case 'TextBox':
            return value
        case 'Select': 
            return getSelectValue(config.Values, value, config.DefaultValue, config)
        case 'MultiSelect':
            return getSelectValue(config.Values, value, config.DefaultValue, config)
        case 'DateDayPicker':
            return value ? moment.utc(value).format('MM/DD/YYYY').toString() : null
        case 'DatePicker':
            return value
                ? moment.utc(value).format('MM/DD/YYYY hh:mm A').toString()
                : null
        case 'CheckBox':
            return getCheckBoxValue(value, config.DefaultValue)
        case 'Creatable':
            return getCreatableSelectValue(config.Values, value, config.DefaultValue)
        default:
            return value
    }
    
}

const transformDBValuesToFormValues = (x, uploadConfig, requiredColumnValues, submissionState) => {
    if (!x) {
        return {}
    }
    return Object.keys(x).reduce((acc, fieldName) => {
        let config = uploadConfig.find(c => c.ColumnName === fieldName)
        const isForeignKey = config && config.ForeignKeyAccessor
        let name = getInputNameFromConfig(config, fieldName)
        // get the values for the parent table, eg SampleID for ResultID
        const requiredColumnConfig = isForeignKey ?
            getForeignKeyRequiredColumnValues(config.ForeignKeyAccessor, fieldName, config.ForeignKeyNameFormat, uploadConfig, submissionState) 
            : requiredColumnValues 

        if (requiredColumnConfig && (requiredColumnConfig.columnName === name || isForeignKey)) {
            name = `${requiredColumnConfig.columnName}Select`
            config = {
                ControlType: 'Select',
                Values: requiredColumnConfig.values,
                DefaultValue: null
            }
        }
        const dbValue = x[fieldName]
        const value = config ? getValueFromUploadConfig(dbValue, config) : dbValue
        return {
            ...acc,
            [name]: value
        }
    }, {})   
}

const getRequiredColumnValues = (requires, submissionState) => {
    if (requires && submissionState[requires] && Array.isArray(submissionState[requires])) {
        const tableName = requires[0].toUpperCase() +
        requires.slice(1)
        const valueAccessor = `Upload${tableName}ID`
        const descriptionAccessor = `${tableName}Name`
        const fieldDuplicateAccessor = `IsFieldDuplicate`
        const values = submissionState[requires].length
            ? submissionState[requires].map(x => ({
                code: x[valueAccessor],
                codedescription: `${x[descriptionAccessor]}${!!x[fieldDuplicateAccessor] ? convertFieldDuplicateString(x[fieldDuplicateAccessor]) : ''}`,
                active: true
            }))
            : null
        return { columnName: valueAccessor, values } 
    } else {
        return null
    }
}

const getDefaultValuesFromSubmissionState = (panelName, uploadConfig, submissionState) => {
    if (panelName) {
        const relatedConfig = uploadConfig.filter(
            x => x.SectionGroupName === panelName
        )
        const subSections = [
            ...new Set(relatedConfig.map(x => x.UploadSectionGroupName)),
        ]
        const defaultValues = subSections.reduce((acc, section) => {
            const sectionData = relatedConfig.filter(
                x => x.UploadSectionGroupName === section
            )
            const exampleRow = sectionData.length && sectionData[0]
            const requires = exampleRow.Requires
            const requiredColumnValues = getRequiredColumnValues(requires, submissionState)
            if (Array.isArray(submissionState[exampleRow.DataAccessor])) {
                const rows = submissionState[exampleRow.DataAccessor]
                const values = rows.map(x => {
                    return transformDBValuesToFormValues(x, uploadConfig, requiredColumnValues, submissionState)
                })
                return {
                    [exampleRow.DataAccessor]: [
                        ...values,
                    ],
                    ...acc
                }
            } else {
                return {
                    [exampleRow.DataAccessor]: {
                        ...transformDBValuesToFormValues(submissionState[exampleRow.DataAccessor], uploadConfig, submissionState),
                    },
                    ...acc
                }

            }
        }, {})
        // console.log('defaultValues:', defaultValues)
        return defaultValues
    } else {
        return {}
    }
}

const FormComponent = ({ panelName, onSubmit }) => {
    const {
        activePanel,
        uploadConfig,
        setFormMethods,
        setFormDirty,
        viewOnly,
        submissionState
    } = useContext(DataContext)
    const defaultValues = useMemo(() => getDefaultValuesFromSubmissionState(panelName, uploadConfig, submissionState), [submissionState, uploadConfig, panelName])
    const formMethods = useForm({ defaultValues: defaultValues, mode: 'onChange' })
    const { formState, watch } = formMethods
    const { dirty } = formState
    const formValues = watch()

    const currentPageData = useMemo(() => {
        if (panelName) {
            const relatedConfig = uploadConfig.filter(
                x => x.SectionGroupName === panelName
            )
            const subSections = [
                ...new Set(relatedConfig.map(x => x.UploadSectionGroupName)),
            ]
            return subSections.map((section, idx) => {
                const sectionData = relatedConfig.filter(
                    x => x.UploadSectionGroupName === section
                )
                const exampleRow = sectionData.length && sectionData[0]
                return {
                    config: sectionData,
                    type: exampleRow.SectionType,
                    accessor: exampleRow.DataAccessor,
                    title: exampleRow.UploadSectionGroupName,
                    subtitle: exampleRow.UploadSectionGroupDescription,
                    key: `form-component-${idx}`,
                }
            })
        } else {
            return []
        }
    }, [panelName, uploadConfig])

    const pageComponents = useMemo(() => {
        return currentPageData.map(d => {
            const componentType = getFormComponentType(d.type)
            return React.createElement(componentType, d)
        })
    }, [currentPageData])

    useEffect(() => {
        if (activePanel === panelName) {
            setFormMethods(formMethods)
        }
    }, [activePanel])

    useEffect(() => {
        setFormDirty(dirty)
    }, [dirty])

    useEffect(() => {
        formMethods.reset(defaultValues)
    }, [activePanel, defaultValues])

    useEffect(() => {
        setReactiveValues(watch({nest: true}), formMethods.setValue)
    }, [formValues])

    return (
        activePanel === panelName && ( 
            <div>
                <LoadingWrapper data={currentPageData}>
                    <FormContext {...formMethods}>
                        <form
                            onSubmit={formMethods.handleSubmit(onSubmit)}
                            noValidate
                            className="columns is-multiline explorerForm is-centered"
                            name={`${panelName}`}
                        >
                            {pageComponents}
                            {!viewOnly && <div className="column is-4 buttonWrapper">
                                <SaveButton />
                                <ResetButton />
                            </div>
                            }                       
                        </form>
                    </FormContext>
                </LoadingWrapper>
            </div>
        )
    )
}

export default () => {
    const { navGroups, uploadConfig, tableData, submissionState, submitForValidation } = useContext(
        DataContext
    )

    const onSubmit = useCallback(data => {
        // perform update to empty array if we set
        // the 'reset' flag on the form (corrseponds to empty array)
        Object.keys(data).map(key => {
            const associatedData = data[key]
            if (associatedData === 'reset') {
                data[key] = []
            } else if (associatedData === 'pageEmpty') {
                data[key] = tableData[key] ? tableData[key] : []
            }
            return null
        })

        // get the values from the data and update the submission state
        // as a merge of the values w/ submission state
        const values = getValuesFromData(data, uploadConfig)
        const copiedData = cloneDeep(submissionState)
        Object.keys(values).map(key => {
            const associatedData = values[key]
            const previousData = copiedData[key]
            if (Array.isArray(associatedData)) {
                const td = cloneDeep(tableData[key])
                if (associatedData === 'reset') {
                    copiedData[key] = []
                } else {
                    const test = td ? merge(td, associatedData) : associatedData
                    copiedData[key] = test ? test : []
                }
            } else {
                copiedData[key] = merge(previousData, associatedData)
            }
            return null
        })
        submitForValidation(copiedData)
    }, [tableData, submissionState, submitForValidation])

    return (
        <div>
            {navGroups.map((group, idx) => {
                const key = `form-component-${idx}`
                return group.type === 'component' ? (
                    React.createElement(group.component, { key: key, ...group.props })
                ) : (
                    <FormComponent
                        key={key}
                        panelName={group.groupName}
                        onSubmit={onSubmit}
                    />
                )
            })}
        </div>
    )
}
