import React, { useMemo } from 'react'

export default ({
    selectedMeasures,
    toggleSelectedMeasures,
    displayMeasureWindowDropdown,
    colorClass,
    measureList,
}) => {
    if (!displayMeasureWindowDropdown) {
        return null
    }
    
    const uniqueList = useMemo(() => {
        let tempList = []
        const displayFields = measureList && measureList.map(x => 
            ({ MeasureType: x.MeasureType, MeasureTypeDisplay: x.MeasureTypeDisplay, MeasureUnit: x.MeasureUnit, MeasureUnitDisplay: x.MeasureUnitDisplay })
        )
        displayFields.forEach(x => {
            if(!tempList.some(y => JSON.stringify(y) === JSON.stringify(x))) {
                tempList.push(x)
            }
        })
        return tempList
    }, [measureList])

    return (
        <div className="analyteDropdownWrapper">
            {uniqueList.map((option, idx) => {
                const active = !!selectedMeasures.find(
                    x => x.MeasureType === option.MeasureType && x.MeasureUnit === option.MeasureUnit
                )
                return (
                    <div
                        key={`timeoption-${idx}`}
                        className={`analyteOptionWrapper ${
                            active ? 'activeOption ' + colorClass : ''
                        }`}
                        onClick={() => {
                            toggleSelectedMeasures(option)
                        }}
                    >
                        <span className="analyteOptionText">
                            {option.MeasureTypeDisplay}
                        </span>
                        <span>{`[${option.MeasureUnitDisplay ? option.MeasureUnitDisplay : 'units unknown'}]`}</span>
                    </div>
                )
            })}
        </div>
    )
}