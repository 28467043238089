import React from 'react'

import ChartTabs from './ChartTabs'
import ChartControls from './ChartControls'
import TimeWindowDropdown from '../TimeWindowDropdown'
import AnalyteDropdown from '../AnalyteDropdown'
import ResetMeasureDropdown from './ResetMeasureDropDown'
import DetailAnalyteChart from './DetailAnalyteChart'
import ResetAnalyteDropDown from './ResetAnalyteDropDown'
import MeasureDropdownDetails from './MeasureDropdownDetails'
import GroupingsDropdownDetails from './GroupingsDropdownDetails'
import DetailPressureChart from './DetailPressureChart'

const DetailChart = () => {
    return (
        <div>
            <ChartTabs />
            <ChartControls />
            <ResetMeasureDropdown />
            <ResetAnalyteDropDown />
            <AnalyteDropdown />
            <MeasureDropdownDetails />
            <GroupingsDropdownDetails />
            <TimeWindowDropdown />
            <DetailAnalyteChart />
            <DetailPressureChart />
        </div>
    )
}

export default DetailChart