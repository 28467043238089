import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
    useCallback,
} from 'react'
import {
    ComposedChart,
    Line,
    YAxis,
    XAxis,
    Tooltip,
    Label,
    CartesianGrid,
    ResponsiveContainer,
    ReferenceArea,
    Legend,
} from 'recharts'

import { DataContext } from './DataContext'
import Message from '../../../../elem/chart/Message'
import Spinner from '../../../../elem/Spinner'
import ChartTooltip from '../../../../elem/chart/Tooltip'
import { dateToString } from '../../../../../utils/dateUtils'
import { AppStateContext } from '../../AppStateContext'
import withConfig from '../../../../wrappers/withConfig'
import dayjs from 'dayjs'
import palette from '../../../../../utils/chart/palette'
import { getAxisYDomain } from '../../../../../utils/chart/slice'
import { roundToFourDigits } from '../../../../../utils/chart/values'

const CustomLegend = ({ legendPayload }) => {
    return (
        <ul className="recharts-default-legend" style={{ textAlign: 'center' }}>
            {legendPayload.map((item, index) => (
                <li
                    key={`line-legend-${index}`}
                    className={`recharts-legend-item legend-item-${index}`}
                    style={{ display: 'inline-block', marginRight: '10px' }}
                >
                    <svg
                        className="recharts-surface"
                        width="14"
                        height="14"
                        viewBox="0 0 32 32"
                        version="1.1"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginRight: '4px',
                        }}
                    >
                        <path
                            stroke="none"
                            fill={item.color}
                            d="M0,4h32v24h-32z"
                            className="recharts-legend-icon"
                        ></path>
                    </svg>
                    <span className="recharts-legend-item-text">
                        {item.value}
                    </span>
                </li>
            ))}
        </ul>
    )
}

export default withConfig(({ config }) => {
    const {
        measureChartData,
        loading,
        tooManyFeatures,
        selectedUnits,
        selectedMeasures,
        zoomTrigger,
         isCollapsed
    } = useContext(DataContext)
    const {
        mapState: { selectedFeatures },
    } = useContext(AppStateContext)

    if(isCollapsed) {
        return null
    }

    const { FACILITY_TITLE } = config

    const [data, setData] = useState([])

    const [left, setLeft] = useState('dataMin')
    const [right, setRight] = useState('dataMax')
    const [refAreaLeft, setRefAreaLeft] = useState('')
    const [refAreaRight, setRefAreaRight] = useState('')
    const [top, setTop] = useState('dataMax')
    const [bottom, setBottom] = useState('dataMin')
    const [top2, setTop2] = useState('dataMax')
    const [bottom2, setBottom2] = useState('dataMin')

    const zoom = useCallback(() => {
        let RAL = refAreaLeft
        let RAR = refAreaRight
        if (RAL === RAR || RAR === '') {
            RAL = ''
            RAL = ''
            return
        }

        // xAxis domain
        if (RAL > RAR) {
            const temp = RAL
            RAL = RAR
            RAR = temp
        }

        // yAxis domain
        const leftAxisDomain = getAxisYDomain(RAL, RAR, (x) => !x.includes('# measures') && !x.includes('DateString'), 0, data, 'DateString')
        setBottom(leftAxisDomain[0])
        setTop(leftAxisDomain[1])

        const rightAxisDomain = getAxisYDomain(RAL,RAR, x => x.includes('# measures') && !x.includes('DateString'), 0, data, 'DateString')
        setBottom2(rightAxisDomain[0])
        setTop2(rightAxisDomain[1])
        setLeft(RAL)
        setRight(RAR)
        setRefAreaRight('')
        setRefAreaLeft('')
    }, [refAreaLeft, refAreaRight, data])

    const zoomOut = useCallback(() => {
        setRefAreaLeft('')
        setRefAreaRight('')
        setLeft('dataMin')
        setRight('dataMax')
        setTop('dataMax')
        setBottom('dataMin')
        setTop2('dataMax')
        setBottom2('dataMin')
    }, [data])

    useEffect(() => {
        zoomOut()
    }, [zoomTrigger])

    const seriesData = useMemo(() => {
        if (measureChartData && measureChartData.length) {
            const dates = [
                ...new Set(
                    measureChartData.reduce((acc, curr) => {
                        return [...acc, ...curr.Data.map(x => x.DateString)]
                    }, [])
                ),
            ]
            const d = dates.map(date => {
                const dateEntry = { DateString: date }
                const allDateData = measureChartData.reduce((acc, curr) => {
                    const measureType = curr.measureType
                    const measureData = curr.Data
                    const matchDate = measureData.find(x => x.DateString === date)
                    const associatedMeasures = selectedMeasures.find(
                        x => x && x.MeasureType === measureType
                    )
                    if (matchDate && associatedMeasures) {
                        const measureName = associatedMeasures.MeasureTypeDisplay
                        const minName = `${measureName} (min)`
                        const maxName = `${measureName} (max)`
                        const medianName = `${measureName} (median)`
                        const numSamplesName = `${measureName} (# measures)`
                        return {
                            ...acc,
                            [minName]: matchDate['Minimum'],
                            [maxName]: matchDate['Maximum'],
                            [medianName]: matchDate['Median'],
                            [numSamplesName]: matchDate['NumberOfSamples'],
                        }
                    } else {
                        return {
                            ...acc,
                        }
                    }
                }, dateEntry)
                return allDateData
            })
            return d
        } else {
            return []
        }
    }, [measureChartData, selectedMeasures])

    useEffect(() => {
        if (seriesData && seriesData.length) {
            const d = seriesData.map(x => ({
                ...x,
                DateString: dayjs(x.DateString)
                    .toDate()
                    .getTime(),
            }))
            setData(d)
        }
    }, [seriesData])

    const uniqueSeries = useMemo(
        () =>
            [
                ...new Set(
                    seriesData.reduce(
                        (acc, curr) => [
                            ...acc,
                            ...Object.keys(curr).filter(
                                x => x !== 'DateString'
                            ),
                        ],
                        []
                    )
                ),
            ]
                .sort()
                .map((x, idx) => ({
                    name: x,
                    color: palette[idx % palette.length],
                })),
        [seriesData]
    )

    const legendData = useMemo(
        () =>
            uniqueSeries &&
            uniqueSeries.map(({ name, color }) => ({
                color,
                type: 'line',
                dataKey: name,
                value: name,
            })),
        [uniqueSeries]
    )

    if (loading) {
        return <Spinner extraClass="chartWrapper" />
    }

    if (tooManyFeatures) {
        return (
            <Message
                text={`Too Many ${FACILITY_TITLE}s Selected`}
                subText={`Select less than 2100 ${FACILITY_TITLE.toLowerCase()}s to generate aggregate statistics`}
            />
        )
    }

    if (!selectedFeatures.length) {
        return (
            <Message
                text={`No ${FACILITY_TITLE}s Selected`}
                subText={`Select up to 2100 ${FACILITY_TITLE.toLowerCase()}s to generate aggregate statistics`}
            />
        )
    }

    if (
        selectedFeatures.length &&
        !(
            measureChartData && measureChartData.length
            && measureChartData.every(e => e.Data && Array.isArray(e.Data) && e.Data.length)
        )
    ) {
        return (
            <Message
                text={'No Pressure-Volume Data Available'}
                subText={`Select ${FACILITY_TITLE.toLowerCase()}s with pressure-volume records in the specified time period to generate aggregate statistics`}
            />
        )
    }

    // construct chart
    const leftXAxisLabel = `Measurement (${selectedUnits})`
    const numberofSamplesAxisLabel = `Number of Measurements`
    const xAxisLabel = 'Measurement Date'

    // shared chart props
    const animationDuration = 200

    // create lines for min, max, and median nitrate levels
    const lines = uniqueSeries.map(({ name, color }, idx) => (
        <Line
            key={`time-series-${name}`}
            yAxisId={name.includes('# measures') ? 1 : 0}
            type="monotone"
            dataKey={name}
            name={name}
            unit={`${name.includes('# measures') ? '' : selectedUnits}`}
            stroke={color}
            strokeWidth={1}
            activeDot={{ r: 5, fill: color, stroke: color }}
            dot={{ r: 2, stroke: color, fill: color }}
            animationDuration={animationDuration}
        />
    ))

    return (
        <div className="chart">
            <div className={`aggregateChartWrapper ${isCollapsed ? 'is-collapsed': ''}`}>
                <ResponsiveContainer width="100%" minHeight={'250px'}>
                    <ComposedChart
                        data={data.slice()}
                        margin={{
                            top: 10,
                            right: 20,
                            left: 25,
                            bottom: 25,
                        }}
                        onMouseDown={e => e && e.activeLabel && setRefAreaLeft(e.activeLabel)}
                        onMouseMove={e => e && e.activeLabel && refAreaLeft && setRefAreaRight(e.activeLabel)}
                        // eslint-disable-next-line react/jsx-no-bind
                        onMouseUp={() => zoom()}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            allowDataOverflow
                            type="number"
                            dataKey="DateString"
                            domain={[left, right]}
                            tickFormatter={unixTime => dateToString(unixTime)}
                        >
                            <Label
                                value={xAxisLabel}
                                offset={-10}
                                position="insideBottom"
                                className="nitrateChartXAxisLabel"
                            />
                        </XAxis>
                        <YAxis 
                            allowDataOverflow 
                            type="number" 
                            domain={[bottom, top]} 
                            tickFormatter={v => roundToFourDigits(v)} 
                            yAxisId={0}
                        >
                            <Label
                                value={leftXAxisLabel}
                                angle={-90}
                                offset={20}
                                position="insideLeft"
                                className="nitrateChartYAxisLabel"
                            />
                        </YAxis>
                        <YAxis
                            allowDataOverflow
                            type="number"
                            orientation="right"
                            domain={[bottom2, top2]}
                            yAxisId={1}
                        >
                            <Label
                                value={numberofSamplesAxisLabel}
                                angle={90}
                                offset={-20}
                                position="insideRight"
                                className="nitrateChartYAxisRightLabel"
                            />
                        </YAxis>
                        <Tooltip content={<ChartTooltip stagger={90}/>} allowEscapeViewBox={{x: true, y: true}}/>
                        <Legend
                            content={
                                <CustomLegend legendPayload={legendData} />
                            }
                            verticalAlign="bottom"
                            wrapperStyle={{
                                bottom: `0`,
                                left: 0,
                                alignItems: 'center',
                                width: '100%',
                            }}
                        />
                        {lines}
                        {/* {dots} */}
                        {refAreaLeft && refAreaRight ? (
                            <ReferenceArea
                                yAxisId={0}
                                x1={refAreaLeft}
                                x2={refAreaRight}
                                strokeOpacity={0.3}
                            />
                        ) : null}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
})
