import React, { useContext } from 'react'
import { DataContext } from '../DataContext'
import AnalyteResetSelector from '../../../../elem/chart/AnalyteResetSelector'

const ResetAnalyteDropDown = () => {
    const { resetZoom, resetAnalytes, analyteResetExpanded, toggleAnalyteResetExpanded } = useContext(DataContext)

    const onResetAnalytes = () => {
        resetAnalytes()
        toggleAnalyteResetExpanded(false)
    }

    const onResetZoom = () => {
        resetZoom()
        toggleAnalyteResetExpanded(false)
    }

    return (
        <AnalyteResetSelector
            resetExpanded={analyteResetExpanded}
            resetAnalytesFunction={onResetAnalytes}
            resetZoomFunction={onResetZoom}
        />
    )
}

export default ResetAnalyteDropDown