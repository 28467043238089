import React from 'react'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'

const parseColumns = (data, dateColumns) => {
    if (data && data.length) {
        const exampleRow = data[0]
        const columns = Object.keys(exampleRow).map(col => {
            const isDate = dateColumns.indexOf(col) !== -1
            const sortType = isDate ? 'datetime' : 'alphanumeric'
            let returnColumn = !isDate ? ({
                Header: () => <div>{col}</div>,
                accessor: col,
                Cell: ({ cell }) => (<div>{cell.value}</div>),
            }) : ({
                Header: () => <div>{col}</div>,
                id: col, //https://stackoverflow.com/questions/53772900/getting-error-a-column-id-is-required-if-using-a-non-string-accessor-for-colum
                accessor: row => {
                    let returnDate
                    const momentDate = moment(row[col])
                    if (momentDate.isValid()) 
                        returnDate = momentDate.toDate()
                    else //hacky solution incoming: if it's not a valid date (i.e null) set the date to an absurdly high value so they get filtered to the bottom in no particular order https://stackoverflow.com/questions/60907412/how-to-sort-array-by-date-when-some-dates-are-null 
                        returnDate = moment('1/1/1002').toDate() // https://stackoverflow.com/questions/28993107/momentjs-how-to-prevent-invalid-date

                    return returnDate 
                }, //https://github.com/TanStack/table/issues/515  
                Cell: ({ cell }) => 
                    moment(cell.value).format('MM/DD/YYYY hh:mm:ss A').includes('01/01/1002') 
                        ? '' : moment(cell.value).format('MM/DD/YYYY hh:mm:ss A'), //MM/DD/YYYY hh:mm:ss A ... see hacky solution up there
                sortType
            })
            return returnColumn
        })
        return columns
    } else {
        return []
    }
}

const parseColumnsWithConfig = (data, config) => {
    if (data && data.length && config && config.length) {
        const exampleRow = data[0]
        const columns = Object.keys(exampleRow).map(col => {
            let columnName = col
            const configObj = config.find(x => x.ColumnName === col)
            if (configObj) {
                columnName = configObj.ColumnTitle
            }
            return {
                Header: () => <div>{columnName}</div>,
                accessor: col,
                Cell: ({ cell }) => {
                    let value = cell.value
                    if (configObj) {
                        if (configObj.Values) {
                            var matchingConfigValue = configObj.Values.filter(
                                x => x.code !== null
                            ).find(
                                x =>
                                    x.code === value ||
                                    x.code.toString() === value.toString()
                            )
                            
                            if (matchingConfigValue && matchingConfigValue.codedescription) {
                                value = matchingConfigValue.codedescription
                            }
                        }
                    }
                    return <div>{value}</div>
                },
            }
        })
        return columns
    } else {
        return []
    }
}

const getInProgressColumns = (data, setConfirmDeleteModalData) => {
    const columns = parseColumns(data, ['Modify Date']).filter(
        x => x.accessor !== 'Link' && x.accessor !== 'IsValid'
    )
        return [
        {
            Header: () => <div>Validation Status</div>,
            accessor: 'IsValid',
            Cell: ({ cell }) => {
                const isValid = cell.row.values.IsValid === 'Pass'
                return (
                    <div>
                        {isValid ? (
                            <span className="icon-text">
                                <span className="icon">
                                    <i className="icon">
                                        <FaCheck className="has-text-success" />
                                    </i>{' '}
                                </span>
                                <span>Pass </span>
                            </span>
                        ) : (
                            <span className="icon-text">
                                <span className="icon">
                                    <i className="icon">
                                        <FaTimes className="has-text-danger" />
                                    </i>{' '}
                                </span>
                                <span>Fail </span>
                            </span>
                        )}
                    </div>
                )
            },
        },
        ...columns,
        {
            Header: () => <div>Actions</div>,
            accessor: 'nothing',
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ cell }) => {
                return (
                    <div>
                        <Link
                            to={
                                cell.row.values.IsEDD
                                    ? `submissions/${cell.row.original.Link}/edd/${cell.row.original['Form ID']}`
                                    : `submissions/${cell.row.original.Link}/form/${cell.row.original['Form ID']}`
                            }
                        >
                            Edit
                        </Link>
                        {` | `}
                        <div
                            className="link inline-block"
                            onClick={() =>{
                                setConfirmDeleteModalData({ 
                                    display: true,
                                    uploadId: cell.row.original['Form ID'],
                                    formType: cell.row.original['Form Type'],
                                })}
                            }                        >
                            Delete
                        </div>
                    </div>
                )
            },
        },
    ]
}

const getSubmittedColumns = (data, isReviewer) => {
    const columns = parseColumns(data, ["Submitted Date", "Approved / Denied Date"]).filter(x => x.accessor !== 'Link' && x.accessor !== 'IsValid')
    return [
        {
            Header: () => <div>Validation Status</div>,
            accessor: 'IsValid',
            Cell: ({ cell }) => {
                const isValid = cell.row.values.IsValid === 'Pass'
                return (
                    <div>
                        {isValid ? (
                            <span className="icon-text">
                                <span className="icon">
                                    <i className="icon">
                                        <FaCheck className="has-text-success" />
                                    </i>{' '}
                                </span>
                                <span>Pass </span>
                            </span>
                        ) : (
                            <span className="icon-text">
                                <span className="icon">
                                    <i className="icon">
                                        <FaTimes className="has-text-danger" />
                                    </i>{' '}
                                </span>
                                <span>Fail </span>
                            </span>
                        )}
                    </div>
                )
            },
        },
        ...columns,
        {
            Header: () => <div>Actions</div>,
            accessor: 'nothing',
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ cell }) => {
                return (
                    <div>
                        <Link
                            to={
                                cell.row.values['Is EDD?'] === 'Yes' 
                                    && cell.row.original['Form Type'] !== 'MIT' 
                                    && cell.row.original['Form Type'] !== 'Pressure-Volume'
                                ? `submissions/${cell.row.original.Link}/edd/view/${cell.row.original['Form ID']}`
                                : `submissions/${cell.row.original.Link}/form/view/${cell.row.original['Form ID']}`
                            }
                        >
                            View
                        </Link>
                        {isReviewer && cell.row.values.Status === 'Submitted' && (
                            <>
                                {` | `}
                                <Link
                                    to={
                                        cell.row.values['Is EDD?'] === 'Yes' 
                                            && cell.row.original['Form Type'] !== 'MIT'
                                            && cell.row.original['Form Type'] !== 'Pressure-Volume'
                                        ? `submissions/${cell.row.original.Link}/edd/review/${cell.row.original['Form ID']}`
                                        : `submissions/${cell.row.original.Link}/form/review/${cell.row.original['Form ID']}`
                                    }
                                >
                                    Review
                                </Link>
                            </>
                        )}
                    </div>
                )
            },
        },
    ]
}

const getEmptyColumnObject = columns => {
    return columns.reduce((acc, curr) => {
        const field = curr.accessor
        return {
            ...acc,
            [field]: null,
        }
    }, {})
}

export {
    getInProgressColumns,
    getSubmittedColumns,
    parseColumns,
    getEmptyColumnObject,
    parseColumnsWithConfig,
}
