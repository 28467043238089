import React, { useContext, useEffect, useState } from 'react'

import { TableContext } from './table/TableContext'
import FormsInProgress from './table/FormsInProgress'
import SubmittedForms from './table/SubmittedForms'
import { SimpleSpinner } from '../../elem/Spinner'
import NewUploadButton from './NewUploadButton'
import { DataContext } from './DataContext'
import BulkSubmitButton from './table/BulkSubmitButton'

const SubmissionTabs = ({ onChange, active }) => (
    <>
        <div className="tabs is-large">
            <ul>
                <li
                    className={`${active === 'inProgress' ? 'is-active' : ''}`}
                    onClick={() => onChange('inProgress')}
                >
                    <span className="link">Forms In Progress</span>
                </li>
                <li
                    className={`${active === 'submitted' ? 'is-active' : ''}`}
                    onClick={() => onChange('submitted')}
                >
                    <span className="link">Submitted Forms</span>
                </li>
            </ul>
            <NewUploadButton />
        </div>
    </>
)

export default () => {
    const { loading } = useContext(TableContext)
    const { initializeState, setActiveAgency, setPrimaryKey, setErrorState} = useContext(DataContext)
    const [activeTab, setActiveTab] = useState('inProgress')
    
    useEffect(() => {
        setActiveAgency(null)
        initializeState({})
        setErrorState({})
        setPrimaryKey(null)
    }, [])

    return (
        <div className="container uploads">
            <SubmissionTabs onChange={setActiveTab} active={activeTab} />
            {
                activeTab === 'inProgress' ? (<BulkSubmitButton action='Submit'/>) : (
                    <div className="columns m-1">
                        <BulkSubmitButton action='Approve'/>
                        <BulkSubmitButton action='Deny'/>
                    </div>
                )
            }
            <div className={`section inProgressSection`}>
                {loading ? (
                    <SimpleSpinner />
                ) : activeTab === 'inProgress' ? (
                    <FormsInProgress />
                ) : (
                    <SubmittedForms />
                )}
            </div>
        </div>
    )
}
