const urlMap = {
    home: '/',
    sample: '/sample-explorer',
    well: '/well-explorer',
    pressure: '/pressure-explorer',
    map: '/map',
    manageSampleResults: `/submissions`,
    uploadSelectionDialogue: '/submissions/new',
    uploadDialogue: '/submissions/:type/new',
    edd: '/submissions/:type/edd',
    viewEDD: '/submissions/:type/edd/view/:uploadId',
    reviewEDD: '/submissions/:type/edd/review/:uploadId',
    form: '/submissions/:type/form',
    editForm: '/submissions/:type/form/:uploadId',
    viewForm: '/submissions/:type/form/view/:uploadId',
    reviewForm: '/submissions/:type/form/review/:uploadId',
    userCallback: '/user/callback'
}

const urls = urlMap

const mapUrlToName = Object.keys(urlMap).reduce((acc, cur) => {
    const name = urlMap[cur]
    return {
        ...acc,
        [name]: cur
    }
}, {})


const isPageExplorer = (location) => {
    const explorerUrls = [urls.home, urls.sample, urls.well]
    return explorerUrls.includes(location.pathname)
}

const createSubmissionUrl = (url, type) => url.replace(':type', type)

export { urls, mapUrlToName, isPageExplorer, createSubmissionUrl }
export default urls