import React from 'react'
import ResetZoomButton from './ResetZoomButton'
import ResetMeasuresButton from './ResetMeasuresButton'

const AggregateResetSelector = ({
    resetExpanded,
    resetZoomFunction,
    resetMeasuresFunction,
}) => {
    if (!resetExpanded) {
        return null
    }
    return (
        <div className="timeWindowDropdownWrapper">
            <ResetZoomButton resetFunction={resetZoomFunction} isInDropdown={true}/>
            <ResetMeasuresButton resetFunction={resetMeasuresFunction} />
        </div>
    )
}

export default AggregateResetSelector