import React, { useCallback, useContext, useMemo } from 'react'

import TableContent from '../../../elem/table/ssr/TableContent'
import { ParameterContext } from '../../../wrappers/ParameterContext'
import { AppStateContext } from '../AppStateContext'
import { DataContext } from './DataContext'
import parseColumns from '../../../../utils/table/parseColumns'
import NoDataComponent from '../../../elem/table/ssr/NoDataComponent'
import withConfig from '../../../wrappers/withConfig'
import Point from 'ol/geom/Point'
import VectorLayer from 'ol/layer/Vector'
import { Feature } from 'ol'

const List = ({ filterExpanded, toggleFilter, config, promotedRecords }) => {
    const { mapState: { selectedFeatures, allFeatures, map }, setMapState } = useContext(AppStateContext)
    const dataContext = useContext(DataContext)
    const { params, setParams } = useContext(ParameterContext)
    const { EXPLORER_LINK_COLUMN, ID_COLUMN } = config
    const { data, loading, meta, filterFields } = dataContext
    const { pageCount, count, pageSize } = meta
    const adjustedPageCount = (count / pageSize) % 1 === 0 ? pageCount - 1 : pageCount
    const columns = useMemo(() => parseColumns(data, filterFields, EXPLORER_LINK_COLUMN, ID_COLUMN))

    // onClick determines behavior of each row -
    // handles highlight and zoom
    const onClick = useCallback((row, isHighlighted) => {
        let newFeatures = selectedFeatures
        if (isHighlighted) {
            // if the row is already highlighted, remove it from the
            // selectedFeatures array on click
            newFeatures = selectedFeatures.filter(x => x.get('FacilityID') !== row.original.FacilityID)
        } else {
            // if the row is not highlighted, add it to the selectedFeatures
            // array on click
            const newFeature = allFeatures.find(x => x.get('FacilityID') === row.original.FacilityID)
            if (newFeature) {
                newFeatures = [...selectedFeatures, newFeature]
                // and zoom to fit the map around the selected feature
                const featurePoint = newFeature.getGeometry()
                map.getView().fit(featurePoint, {padding: [170, 50, 30, 150], minResolution: 50})        
            } else {
                let feature = new Feature({
                    geometry: new Point([0, 0])
                });
                feature.setProperties({
                    FacilityID: row.original.FacilityID,
                    HasFlowData: 0,
                    HasPressureData: 0,
                    RBDMSIDString: row.original.RBDMSIDString,
                    displayed: 0,
                    geometry: new Point([0, 0]),
                    selected: 0,
                }, 1)
                newFeatures = [...selectedFeatures, feature]
            }
        }
        setMapState(prevMapState => ({
            ...prevMapState,
            selectedFeatures: newFeatures ? newFeatures : prevMapState.selectedFeatures
        }))
    }, [selectedFeatures])

    const selectedItems = useMemo(() => selectedFeatures.map(feature => feature.get(ID_COLUMN), [ID_COLUMN, selectedFeatures]))

    if (count === 0) {
        return <NoDataComponent filterExpanded={filterExpanded} toggleFilter={toggleFilter} />
    }
    return (
        <TableContent
            name="sample"
            columns={columns}
            data={data}
            loading={loading}
            pageCount={adjustedPageCount}
            resultsCount={count}
            params={params}
            onClick={onClick}
            setParams={setParams}
            filterFields={filterFields}
            filterExpanded={filterExpanded}
            selectedItems={selectedItems}
            selectedItemColumn={ID_COLUMN}
            promotedRecords={promotedRecords}
        />
    )
}

export default withConfig(List)
