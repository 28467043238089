import React from 'react'

import { UpArrow, DownArrow } from '../../Arrows'

export default ({ 
    displayMeasureWindowDropdown,
    toggleMeasureWindowDropdown,
    inHeader=true
}) => {
    return (
        <div
            className="panelButton"
            onClick={() => toggleMeasureWindowDropdown(!displayMeasureWindowDropdown)}
        >
            <span className="panelTimeOptionText">Measures</span>
            <span className="panelTimeOptionDropdown">
                {displayMeasureWindowDropdown ? (
                    <UpArrow color={'white'} className={`${inHeader ? 'is-white' : ''}`} />
                ) : (
                    <DownArrow color={'white'} className={`${inHeader ? 'is-white' : ''}`} />
                )}
            </span>
        </div>
    )
}
