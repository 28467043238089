import React, { useContext, useEffect, useState } from 'react'

import { DataContext } from '../DataContext'

export default () => {
    const { activePanel, navGroups, errorState, uploadConfig } = useContext(DataContext)
    const [errors, setErrors] = useState(null)
    const [warnings, setWarnings] = useState(null)

    useEffect(() => {
        const applicableAccessors = [...new Set(uploadConfig.filter(x => x.SectionGroupName === activePanel).map(x => x.DataAccessor))]
        let generalErrors = Object.keys(errorState).filter(x => x.includes('[')).reduce((acc, curr) => {
          const errors = errorState[curr].filter(x => x.fieldName === "")
          const dataAccessor = curr.split('[')[0]
          if (errors) {
              if (applicableAccessors.includes(dataAccessor)) {
                return [
                    ...acc,
                    ...errors
                ]
              }
          }
          return acc
        }, [])
    
        // general table errors are in the key errorState[dataAccessor]
        const generalTableErrors = Object.keys(errorState)
        .reduce((acc, curr) => {
          const errors = errorState[curr].filter(x => x.fieldName === "")
          const dataAccessor = curr
          if (errors) {
              if (applicableAccessors.includes(dataAccessor)) {
                return [
                    ...acc,
                    ...errors
                ]
              }
          }
          return acc
        }, [])

        generalErrors = [
            ...generalErrors,
            ...generalTableErrors
        ]
    
        setErrors(generalErrors ? generalErrors.filter(x => x.severity === 'Error') : null)
        setWarnings(generalErrors ? generalErrors.filter(x => x.severity === 'Warning') : null)
        
    }, [activePanel, navGroups, errorState, uploadConfig])

    if (!errors) {
        return null
    } else {
        return (
            <div>
                <ul>
                    {errors.map(x => (
                        <li className="has-text-danger-dark">
                            {x.errorString}
                        </li>
                    ))}
                    {warnings.map(x => (
                        <li className="has-text-warning-dark">
                            {x.errorString}
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}
