import React, { useContext } from 'react'

import { DataContext } from '../DataContext'
import MeasureSelector from '../../../../elem/chart/MeasureSelector/MeasureSelector'

export default () => {
    const {
        selectedMeasures,
        toggleSelectedMeasures,
        displayMeasureWindowDropdown,
        measureList,
    } = useContext(DataContext)

    if (!measureList || !measureList.length) {
        return null
    }

    return (
        <MeasureSelector
            selectedMeasures={selectedMeasures}
            toggleSelectedMeasures={toggleSelectedMeasures}
            measureList={measureList}
            displayMeasureWindowDropdown={displayMeasureWindowDropdown}
            colorClass={`color-2`}
        />
    )
}
