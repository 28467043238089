import React, { useContext } from 'react'
import { DataContext } from '../DataContext'

export default ({ formId }) => {
    const { resetState } = useContext(DataContext)
    let buttonProps = {
        className: 'button is-medium',
        type: 'button',
        onClick: async () => await resetState(),
    }
    if (formId) {
        buttonProps = {
            ...buttonProps,
            form: formId,
        }
    }
    return (
        <div className="saveButtonWrapper">
            <button {...buttonProps}>{`Reset`}</button>
        </div>
    )
}
