import React from 'react'

export default ({
    selectedAnalytes,
    toggleSelectedAnalyte,
    analyteList,
    displayAnalyteWindowDropdown,
    colorClass,
}) => {
    if (!displayAnalyteWindowDropdown) {
        return null
    }

    return (
        <div className="analyteDropdownWrapper">
            {analyteList.map((option, idx) => {
                const active = !!selectedAnalytes.find(
                    x => x.Analyte === option.Analyte
                )
                return (
                    <div
                        key={`timeoption-${idx}`}
                        className={`analyteOptionWrapper ${
                            active ? 'activeOption ' + colorClass : ''
                        }`}
                        onClick={() => {
                            toggleSelectedAnalyte(option)
                        }}
                    >
                        <span className="analyteOptionText">
                            {option.Analyte}
                        </span>
                        <span>{`[${option.Units ? option.Units : 'units unknown'}]`}</span>
                    </div>
                )
            })}
        </div>
    )
}
