import React, { useContext, useEffect, useCallback } from 'react'
import { FaExclamationTriangle, FaTimesCircle } from 'react-icons/fa'
import { DataContext } from '../DataContext'
import { warningsInNavgroup, errorsInNavgroup } from '../../../../utils/submissions/values'
import { canNavigate } from '../../../../utils/submissions/navigation'

const isNaNOrNull = value => value == null || isNaN(value)

export default () => {
    const {
        activePanel,
        changeActivePanel,
        navGroups,
        submissionState,
        navigate,
        isSubmitting,
        errorState,
        primaryKey,
        formType,
        uploadConfig,
        isSearchTabVisible
    } = useContext(DataContext)

    useEffect(() => {
        if (navGroups && navGroups.length) {
            if (
                submissionState &&
                submissionState[formType.PrimaryAccessor] &&
                submissionState[formType.PrimaryAccessor][formType.UploadKeyField] &&
                isSearchTabVisible
            ) {
                changeActivePanel(navGroups[1].groupName)
            } else {
                changeActivePanel(navGroups[0].groupName)
            }
        }
    }, [navGroups])

    const navigateAndContinue = useCallback(
        group => {
            if (!isNaNOrNull(primaryKey)) {
                navigate(group)
                    .then(() => {
                        changeActivePanel(group.groupName)
                    })
                    .catch(() => {
                        // if this fails, notify for unsaved changes
                    })
            }
        },
        [navigate, primaryKey]
    )

    const groupHasErrors = useCallback(
        groupName => errorsInNavgroup(groupName, errorState, uploadConfig),
        [errorState, uploadConfig]
    )

    const groupHasWarnings = useCallback(
        groupName => warningsInNavgroup(groupName, errorState, uploadConfig),
        [errorState, uploadConfig]
    )

    return (
        <aside className="menu">
            <ul className="menu-list">
                {navGroups.map((group, idx) => {
                    const navigateEnabled =
                        !isSubmitting && !isNaNOrNull(primaryKey) && canNavigate(group, submissionState)
                    const isActive = group.groupName === activePanel
                    const hasWarnings = groupHasWarnings(
                        group.groupName,
                        errorState
                    )
                    const hasErrors = groupHasErrors(
                        group.groupName,
                        errorState
                    )
                    return (
                        <li key={`nav-item-${idx}`}>
                            <div
                                className={`link sideNavLink ${
                                    isActive ? 'is-active' : ''
                                } ${navigateEnabled ? '' : 'disabled-link'}`}
                                onClick={() => navigateAndContinue(group)}
                            >
                                <div className="text">
                                    {group.groupName}
                                </div>
                                <div className="icons">
                                    {hasWarnings && <FaExclamationTriangle className={'has-text-warning'}/>}
                                    {hasErrors && <FaTimesCircle className={'has-text-danger'}/>}
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </aside>
    )
}
