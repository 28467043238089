import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import EditableTable from '../../../elem/table/EditableTable'
import { parseUploadColumns } from '../../../../utils/table/parseColumns'
import { DataContext } from '../DataContext'
import SectionWrapper from './SectionWrapper'
import GeneralErrors from './GeneralErrors'
import deepClone from 'lodash.clonedeep'
import PageErrorText from './PageErrorText'
import { useFormContext } from 'react-hook-form'
import ConfirmDeleteRecord from './ConfirmDeleteRecord'
import deleteFunction from '../table/deleteFunction'

const getInputNameFromConfig = (config, fieldName) => {
    if (!config) {
        return fieldName
    }
    const name = config.ColumnName
    switch (config.ControlType) {
        case 'TextBox':
            return name
        case 'Select':
            return `${name}Select`
        case 'MultiSelect':
            return `${name}Select`
        case 'DateDayPicker':
            return `${name}DateSelect`
        case 'CheckBox':
            return `${name}CheckBox`
        case 'DatePicker':
            return `${name}DateSelect`
        case 'Creatable':
            return `${name}Select`
        default:
            return name
    }
}

const getDataFromSubmissionState = (submissionState, accessor) =>
    submissionState && submissionState[accessor]
        ? submissionState[accessor]
        : []

export default ({ config, accessor, title, subtitle }) => {
    const {
        submissionState,
        formMethods,
        viewOnly,
        setTableData,
        setFormDirty,
        updateControlledPageSize,
        pageErrorText,
        beforePageChange,
        setDisplayDeleteModal,
        errorState,
        setErrorState,
        formType,
        isDisabledBasedOnGEM,
    } = useContext(DataContext)

    const { setValue } = useFormContext()

    const [data, setData] = useState(getDataFromSubmissionState(submissionState, accessor))
    const enableRowCreation = useMemo(() => !viewOnly && !config.some(x => x.DisableRowCreation) && !isDisabledBasedOnGEM, [config, viewOnly, submissionState, formType])
    const removeAndShiftErrorState = useCallback((deletedRecordaccessor, rowIdx) => {
        if (!errorState || Object.keys(errorState).length === 0) {
            return null
        }
        const dummyErrorState = errorState
        const deletedRowName = `${deletedRecordaccessor}[${rowIdx}]`
        const deletedRowPosition = deletedRowName.split(deletedRecordaccessor)[1][1]
        delete dummyErrorState[deletedRowName]
        let elementsAfterDeletedRow = {}
        let unchangedElements = {}
        for (const rowName in dummyErrorState) {
            const rowAccessor = rowName.split('[')
            if (rowAccessor.length > 1) { // NW-356 check that rowAccessor actually belongs to a grid error
                const position = rowAccessor[1][0] //get the index part of the accessor (which is formatted like `accessor[x]`) as a string this will return just the `x]` part, then get the item in the middle of that string, to get the position 
                const rowData = dummyErrorState[rowName]
    
                if (deletedRowPosition <= position && deletedRecordaccessor === rowAccessor[0]) { // this means we need to shift positions
                    delete dummyErrorState[rowName]  
                    const formattedRowName = `${rowAccessor[0]}[${position - 1}]` //insert it again but now shifted up
                    elementsAfterDeletedRow = { ...elementsAfterDeletedRow, [formattedRowName]: rowData }
                }
                else {
                    const formattedRowName = `${rowAccessor[0]}[${position}]` // if we didn't deleted it insert the same thing again   
                    unchangedElements = { ...unchangedElements, [formattedRowName]: rowData }
                }   
            }
        }
        setErrorState(
            {...unchangedElements, ...elementsAfterDeletedRow }
        )
    }, [errorState])

    const columns = useMemo(
        () =>
            parseUploadColumns(
                [],
                config,
                deleteFunction,
                submissionState,
                viewOnly,
                setDisplayDeleteModal,
                formType,
            ),
        [config, formMethods, accessor, submissionState, viewOnly]
    )

    const fullConfigs = useMemo(() => columns ? columns.map(x => x.fieldConfigs) : config, [columns, config])
    
    useEffect(() => {
        setData(getDataFromSubmissionState(submissionState, accessor))
    }, [config, submissionState])

    useEffect(() => {
        if (data.length) {
            const lastRowIdx = data.length - 1
            const lastRow = data[lastRowIdx]
            if (!Object.keys(lastRow).some(x => lastRow[x] !== null)) {
                // TODO: When we add a row, make sure the form data is cleared
                Object.keys(lastRow).map(key => {
                    const associatedConfig = config.find(x => x.ControlName === key)
                    const fieldName = getInputNameFromConfig(associatedConfig, key) 
                    const formName = `${accessor}[${lastRowIdx}].${fieldName}`

                    // hack for the id keys in dependent tables
                    if (formName.includes('ID') && !formName.includes('Select')) {
                        return setValue(formName.replace('ID', 'IDSelect'), null)   
                    }
                    return setValue(formName, null)
                })
            }
        }
    }, [data, submissionState, accessor, setValue, config])

    const boundDeleteFunction = useMemo(() => {
        return deleteFunction.bind(
            this,
            setData,
            formMethods,
            accessor,
            setFormDirty,
            fullConfigs,
            removeAndShiftErrorState
        )
    }, [setData,
        formMethods,
        accessor,
        setFormDirty,
        fullConfigs,
        removeAndShiftErrorState]
    )

    useEffect(() => {
        setTableData(prevTableData => {
            const newTableData = deepClone(prevTableData)
            newTableData[accessor] = data
            return newTableData
        })
    }, [data, accessor])
    
    const tableData = useMemo(() => data, [data])
    return (
        <SectionWrapper title={title} subtitle={subtitle}>
            <PageErrorText string={pageErrorText[accessor]} />
            <GeneralErrors />
            <div className="formTableWrapper">
                <ConfirmDeleteRecord 
                    accessor={accessor}
                    deleteFunction={boundDeleteFunction}
                />
                <EditableTable
                    columns={columns}
                    data={tableData}
                    setData={!viewOnly ? setData : null}
                    enableRowCreation={enableRowCreation}
                    setControlledPageSize={updateControlledPageSize.bind(this, accessor)}
                    beforePageChange={beforePageChange}
                />
            </div>
        </SectionWrapper>
    )
}
