import { getValueFromDataRow } from '../../../../utils/submissions/values'

const removeAndShiftRemaining = (data, rowIdx) => {
    // keep the first elements of the array the same
    const keep = data.slice(0, rowIdx)
    // shift the elements after the deleted row into the other places
    const remaining = data.slice(rowIdx)
    remaining.shift()
    const splicedData = [...keep, ...remaining]
    return splicedData
}

const deleteFunction = (
    setData,
    formMethods,
    accessor,
    setFormDirty,
    config,
    removeAndShiftErrorState,
    tableProps,
) => {
    const { cell, data } = tableProps
    const rowIdx = cell.row.index
    // unregister the last row and set values in react-hook-form to new values
    const formValues = formMethods.getValues({ nest: true })

    const associatedValues = formValues[accessor]
    const lastVisibleIndex = associatedValues.length - 1
    associatedValues.map((entry, idx) => {
        if (idx >= rowIdx && idx < lastVisibleIndex) {
            const newValue = associatedValues[idx + 1]
            const fieldToUpdate = `${accessor}[${idx}]`
            Object.keys(entry).map(key => {
                const value = newValue[key]
                // const value = get(newValue, key)
                return formMethods.setValue(
                    `${fieldToUpdate}.${key}`,
                    !(value === null || value === undefined) ? value : null
                )
            })
        }
        return null
    })

    const revIdx = associatedValues.slice().reverse().findIndex(x => !!x)
    const lastRowIdx = lastVisibleIndex - revIdx
    const lastRow = formValues[accessor][lastRowIdx]
    if (lastRowIdx === data.length - 1) {
        // unregister the fields associated with the last row
        Object.keys(lastRow).map(key => {
            const fieldName = `${accessor}[${lastRowIdx}].${key}`
            formMethods.setValue(fieldName, null)
            formMethods.unregister(`${accessor}[${lastRowIdx}].${key}`)
            return null
        })
    } else {
        const newRow = data[lastRowIdx + 1]
        Object.keys(lastRow).map(key => {
            const fieldName = `${accessor}[${lastRowIdx}].${key}`
            const newValue = getValueFromDataRow(key, newRow, config)
            formMethods.setValue(fieldName, newValue)
            return null
        })
    }

    // if this is the last row, register a flag to denote this for data update
    if (lastRowIdx === 0) {
        formMethods.register(`${accessor}`)
        formMethods.setValue(`${accessor}`, 'reset')
    } else if (associatedValues.filter(x => !!x).length - 1 === 0) {
        formMethods.register(`${accessor}`)
        formMethods.setValue(`${accessor}`, 'pageEmpty')
    }
    removeAndShiftErrorState(accessor, rowIdx) //take the API errors and delete them along side the deleted column
    // update the data associated with the table
    setData(existingData => {
        const newData = removeAndShiftRemaining(existingData, rowIdx)
        return newData
    })
    setFormDirty(true)
}

export default deleteFunction
