import React, { useContext } from 'react'

import { DataContext } from '../DataContext'
import GroupingSelector from '../../../../elem/chart/MeasureSelector/GroupingSelector'

export default () => {
    const {
        selectedGrouping,
        setSelectedGrouping,
        displayGroupingWindowDropdown,
        groupingList,
    } = useContext(DataContext)


    if (!groupingList || !groupingList.length) {
        return null
    }

    return (
        <GroupingSelector
            selectedGrouping={selectedGrouping}
            setSelectedGrouping={setSelectedGrouping}
            displayGroupingWindowDropdown={displayGroupingWindowDropdown}
            colorClass={`color-2`}
            groupingList={groupingList}
        />
    )
}
