export default {
    'facility': {
        project: {},
        projectAlias: {},
        address: {},
        projectFacility: []
    },
    'samplingPoint': {
        aquifer: [],
        construction: [],
        facility: {},
        facilityAlias: {},
        waterLevel: [],
        lithology: [],
        result: [],
        sample: []
    },
    'mit': {
        agent: {},
        mit: [],
        facilityAgentMIT: []
    },
    'pressure': {
        monitorReq: {}, 
        monitorMeasure: [],
        monitorReqParameter: [],
        facilityStatus: []
    }
}
