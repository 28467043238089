import React, { useContext } from 'react'

import { DataContext } from '../../DataContext'
import Assignment from './Assignment'
import FacilityAssignmentMITContextProvider from './DataContext'
import PanelStyleContextProvider from '../../../../elem/panel/PanelStyleContext'

import FacilityFilter from './FacilityFilter'

const MITFacilityAssignment = () => {
    const {
        activePanel,
        viewOnly,
    } = useContext(DataContext)

    return (
        <div
            className={`columns is-multiline
            ${activePanel !== 'Sampling Point Assignments' ? 'is-hidden' : ''}
        `}
        >
            <FacilityAssignmentMITContextProvider>
                <PanelStyleContextProvider panelClassConfig={{}}>
                    {!viewOnly && <FacilityFilter />}
                    <Assignment />
                </PanelStyleContextProvider>
            </FacilityAssignmentMITContextProvider>
        </div>
    )
}

export default MITFacilityAssignment
