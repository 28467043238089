const well = {
    PageSize: 50,
    Page: 1,
}

const sample = {
    PageSize: 50,
    Page: 1,
}

const pressure = {
    PageSize: 50,
    Page: 1,
}

const defaultParams = {
    well: { well, sample: {}, pressure: {} },
    sample: { sample, well: {}, pressure: {} },
    pressure: { pressure, sample: {}, well: {} },
    home: { well, pressure, sample, app: { promoted: false } },
    map: {},
}

export { defaultParams }
